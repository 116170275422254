import { Observable, of, filter, switchMap, tap } from 'rxjs';

export const skipEmpty =
  () =>
  <T>(source: Observable<T>): Observable<T> =>
    source.pipe(filter((value: any) => !!value));

export function startWithTap<T>(callback: () => void) {
  return (source: Observable<T>) =>
    of({}).pipe(
      tap(callback),
      switchMap((o) => source),
    );
}
