export interface PostLocation {
  location: RegExp;
  target: string;
  params?: any;
}

export const POSTLOCATIONS: PostLocation[] = [
  /* Geofencing */
  { location: /^admin\/geofence\/$/, target: 'planning/geofencing/' },
  { location: /^admin\/geofence\/form\/$/, target: 'planning/geofencing/add/' },
  { location: /^admin\/geofence\/form\/(\d+)$/, target: 'planning/geofencing/edit/{1}/' },
  /* Obszary */
  { location: /^admin\/uregions\/$/, target: 'planning/user-regions/' },
  { location: /^admin\/uregions\/lista\/$/, target: 'planning/user-regions/' },
  { location: /^admin\/uregions\/form\/$/, target: 'planning/user-regions/add/' },
  { location: /^admin\/uregions\/form\/(\d+)$/, target: 'planning/user-regions/edit/{1}/' },
  /* Punkty użytkownika */
  { location: /^admin\/upoints\/$/, target: 'planning/user-points/' },
  { location: /^admin\/upoints\/form\/$/, target: 'planning/user-points/add/' },
  { location: /^admin\/upoints\/form\/(\d+)$/, target: 'planning/user-points/edit/{1}/' },
  /* Trasy */
  { location: /^admin\/direction\/$/, target: 'planning' },
  { location: /^admin\/direction\/lista\/$/, target: 'planning' },
  {
    location: /^admin\/maps\/trasa\/new$/,
    target: 'map/route-creator',
    params: { backUrl: '/planning' },
  },
  {
    location: /^\/admin\/maps\/trasa\/(.*)\/edycja$/,
    target: 'map/route-creator/route/{1}/edit',
    params: { backUrl: '/planning' },
  },
  /* Korytarze */
  {
    location: /^admin\/maps\/trasa\/(.*)\/korytarz/,
    target: 'map/route-creator/route/{1}/corridor/add',
    params: { backUrl: '/planning' },
  },
  { location: /^admin\/directioncorridors\/listatuneli$/, target: 'planning/vehicle-corridors' },
  {
    location: /^\/admin\/maps\/trasa\/(.*)\/korytarz\/(.*)$/,
    target: 'map/route-creator/route/{1}/corridor/{2}/edit',
    params: { backUrl: '/planning/vehicle-corridors' },
  },
  /* Komunikacja SMS */
  { location: /^\/admin\/sms\/form\/$/, target: 'communication/sms/new' },
  { location: /^\/admin\/sms\/lista\/$/, target: 'communication/sms/' },
  /* Zlecenia */
  {
    location: /^\/admin\/maps\/orders\/(\d+)$/,
    target: 'map/route-creator/order/{1}/corridor/add',
    params: { backUrl: '/orders' },
  },
  { location: /^admin\/orders\/neworder\/$/, target: 'orders/add' },
  { location: /^\/admin\/orders\/editorder\/(\d+)$/, target: 'orders/edit/{1}' },
  { location: /^admin\/orders\/$/, target: 'orders' },
  /* Ustawienia - Pojazdy */
  { location: /^admin\/vehicles\/$/, target: 'settings' },
  { location: /^admin\/vehicles\/form\/(\d+)\?ref=(vv|vl)$/, target: 'settings/vehicles/edit/{1}' },
  /* Ustawienia - Grupy pojazdów */
  { location: /^admin\/vehicles\/grouplist\/$/, target: 'settings/vehicle-groups' },
  { location: /^admin\/vehicles\/formgrupa\/$/, target: 'settings/vehicle-groups/add' },
  { location: /^admin\/vehicles\/formgrupa\/(\d+)$/, target: 'settings/vehicle-groups/edit/{1}' },
  /* Ustawienia - Kierowcy */
  { location: /^admin\/drivers\/$/, target: 'settings/drivers' },
  { location: /^.*admin\/drivers\/form\/$/, target: 'settings/drivers/add/' },
  {
    location: /^.*admin\/drivers\/form\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/,
    target: 'settings/drivers/edit/{1}',
  },
  { location: /^.*admin\/drivers\/form\/\?p=(\w+)$/, target: 'settings/drivers/add/{1}/card' },
  /* Ustawienia - Linki tymczasowe */
  { location: /^admin\/tmplinks\/$/, target: 'settings/temporary-links' },
  { location: /^admin\/tmplinks\/form\/$/, target: 'settings/temporary-links/add/' },
  /* Ustawienia - Reguły alertów */
  { location: /^admin\/alerty\/?$/, target: 'settings/alerts-rules' },
  { location: /^admin\/alerty\/form\/?$/, target: 'settings/alerts-rules/add/' },
  { location: /^admin\/alerty\/form\/([\-a-zA-z0-9]+)$/, target: 'settings/alerts-rules/edit/{1}' },
  { location: /^admin\/alerty\/form\/\?pojazd=(\d+)$/, target: 'settings/alerts-rules/add-for-car/{1}' },
  /* Ustawienia - Słowniki */
  { location: /^admin\/dictionaries\/?$/, target: 'settings/dictionaries' },
  { location: /^admin\/dictionaries\/form\/$/, target: 'settings/dictionaries/add/' },
  { location: /^admin\/dictionaries\/form\/(\d+)$/, target: 'settings/dictionaries/edit/{1}' },
  /* Ustawienia - Konfiguracja */
  { location: /^admin\/cnfmod\/?$/, target: 'settings/configuration' },
  /* Ustawienia - Użytkownicy */
  { location: /^admin\/manage\/?$/, target: 'settings/users' },
  { location: /^admin\/manage\/form\/$/, target: 'settings/users/add/' },
  { location: /^admin\/manage\/form\/(\d+)$/, target: 'settings/users/edit/{1}' },
  { location: /^admin\/manage\/permview/, target: 'settings/users/view/permissions' },
  { location: /^admin\/manage\/regionviewbyregion/, target: 'settings/users/view/region-by-region' },
  { location: /^admin\/manage\/regionviewbyvehicle/, target: 'settings/users/view/region-by-vehicle' },
  /* Ustawienia - Delegacje */
  { location: /^admin\/delegations\/?$/, target: 'settings/delegations' },
  { location: /^admin\/delegations\/form\/?$/, target: 'settings/delegations/add/' },
  { location: /^admin\/delegations\/form\/(\d+)$/, target: 'settings/delegations/edit/{1}' },
  { location: /^admin\/delegations\/form\/([a-f\d]{24})$/, target: 'settings/delegations/edit/{1}' },
  /* Ustawienia - Dostępy */
  { location: /^admin\/dostepy/, target: 'settings/accesses' },
  /* Ustawienia - Opłacalność zleceń */
  { location: /^admin\/iszt\/?$/, target: 'settings/orders-profitability' },
  /* Historia */
  { location: /^\/admin\/trasa\/\?btnStart=(.+)\&btnKoniec=(.+)\&boxid=(\d+)$/, target: 'history/{1}/{2}/{3}' },
  {
    location: /^\/admin\/trasa\/\?calChange=(\d+)\&boxid=(\d+)\&btnStart=(.+)\&btnKoniec=(.+)\&entryLimit=(\d+)$/,
    target: 'history/{1}/{2}/{3}/{4}/{5}',
  },
  { location: /^\/?admin\/trasa\/?(#[a-z_]*)?$/, target: 'history' },
  /* Pojazdy */
  { location: /^admin\/maps\/vehicleview$/, target: 'vehicle-data' },
  /* Raport cykliczny */
  { location: /^admin\/reportsgenerator\/lista\/$/, target: 'reports/cyclic' },
  { location: /^admin\/reportsgenerator\/blankreport/, target: 'reports/add' },
  { location: /^admin\/reportsgenerator\/editreport\?id=(\d+)$/, target: 'reports/edit/{1}' },
  { location: /^admin\/reportsloader\/\?boxId=(\d+)\&dateFrom=(.+)\&dateTo=(.+)$/, target: 'reports/{1}/{2}/{3}' },
  /* Statusy kierowcow */
  { location: /^admin\/driversstatus\/?$/, target: 'settings/drivers-statuses' },
  /* Mapa */
  { location: /^\/admin\/maps\/?$/, target: 'map' },
];
