import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject, Observable, firstValueFrom, tap } from 'rxjs';

import { environment } from '@app/../environments/environment';
import { LocalStorageKeys, LocalStorageService } from '@app/@shared/local-storage';
import { SsoService } from '@core/auth/services/sso.service';
import { SUPPORTED_LANGUAGE } from '@shared/consts';

@Injectable()
export class ChangeLanguageService {
  private _isOpenedSource: Subject<boolean> = new Subject<boolean>();
  private _isOpened$: Observable<boolean> = this._isOpenedSource.asObservable();
  private readonly _defaultLang: string = SUPPORTED_LANGUAGE.PL;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private translate: TranslateService,
    private cookies: CookieService,
    private readonly localStorageService: LocalStorageService,
    private readonly ssoService: SsoService,
  ) {}

  public open(): void {
    this._isOpenedSource.next(true);
  }

  public close(): void {
    this._isOpenedSource.next(false);
  }

  public get isOpened$(): Observable<boolean> {
    return this._isOpened$;
  }

  public initLanguage(): Promise<void> {
    let locale: string;
    const currentLanguage = this.localStorageService.getItem(LocalStorageKeys.Language);
    const ssoCookieLanguage = this.ssoService.getLanguage();

    if (Object.values(SUPPORTED_LANGUAGE).includes(<any>ssoCookieLanguage)) {
      locale = ssoCookieLanguage;
    } else if (currentLanguage) {
      locale = currentLanguage;
    } else {
      locale = this.translate.getBrowserCultureLang();
    }

    if (environment.production === false && currentLanguage === '') {
      /* Used to show translations key on UI */
      this.translate.setDefaultLang('none');
      locale = 'none';
    } else {
      this.translate.setDefaultLang(this._defaultLang);
    }

    return firstValueFrom(this._setLanguage(locale));
  }

  public getCurrentLang(): string {
    return this.translate.currentLang;
  }

  public getLanguageByLocaleID(localeID: string): string {
    if (localeID === 'none') {
      return 'none';
    }

    localeID = localeID.toLowerCase();
    const locale: string = localeID.toLowerCase().slice(0, 2);

    if (Object.values(SUPPORTED_LANGUAGE).includes(<any>locale)) {
      return locale;
    }

    return this._defaultLang;
  }

  public setLanguage(localeID: string): void {
    this._setLanguage(localeID).subscribe(() => {
      window.location.reload();
    });
  }

  private _setLanguage(localeID: string): Observable<void> {
    const language: string = this.getLanguageByLocaleID(localeID);

    return this.translate.use(language).pipe(
      tap(() => {
        this.rememberLanguage(language);
      }),
    );
  }

  private rememberLanguage(localeID: string): void {
    this.localStorageService.setItem(LocalStorageKeys.Language, localeID);
    this.ssoService.setLanguage(localeID);
  }
}
