import { InMemoryCache } from '@apollo/client/core';

import result, { ReferenceType, TypedTypePolicies, Vehicle as VehicleModel, Driver as DriverModel } from './generated';
import {
  Task,
  EtaArrival,
  EtaLightTask,
  OrderTask,
  MapModuleSettings,
  Driver,
  DriverUnknown,
  Vehicle,
  VehicleCurrentType,
  MapModuleClientSettings,
  Incident,
  VehicleDeviceType,
  DriverStatusType,
  UserPoint,
  VehicleGroupType,
  Eta,
  DriverCard,
  TachoRemoteCompanyCardType,
} from './type-policies';

const typePolicies: TypedTypePolicies = {
  Task,
  EtaArrival,
  EtaLightTask,
  Incident,
  OrderTask,
  MapModuleSettings,
  Driver,
  DriverUnknown,
  Vehicle,
  VehicleDeviceType,
  VehicleCurrentType,
  DriverStatusType,
  MapModuleClientSettings,
  UserPoint,
  VehicleGroupType,
  Eta,
  DriverCard,
  TachoRemoteCompanyCardType,
  Me: {
    fields: {
      vehicles: {
        read(values, { args, toReference }) {
          if (args?.id != null) {
            return [toReference(<ReferenceType<VehicleModel>>{ __typename: 'Vehicle', id: args.id })];
          }

          return values;
        },
      },
      drivers: {
        read(values, { args, toReference }) {
          if (args?.id != null) {
            return [toReference(<ReferenceType<DriverModel>>{ __typename: 'Driver', id: args.id })];
          }

          return values;
        },
      },
    },
  },
  TachoRemoteType: {
    merge: true,
  },
  Query: {
    fields: {
      me: {
        merge: true,
      },
    },
  },
  Mutation: {
    fields: {
      etaLightTask: {
        merge: true,
      },
    },
  },
};

export const memoryCache: () => InMemoryCache = () => {
  return new InMemoryCache({
    possibleTypes: result.possibleTypes,
    typePolicies,
  });
};
