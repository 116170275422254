import { TypedTypePolicies } from '../generated';

export const DriverStatusType: TypedTypePolicies['DriverStatusType'] = {
  fields: {
    date: {
      read(value): Date | null {
        return value ? new Date(value) : value;
      },
    },
  },
};
