import { inject, Injectable, Injector } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

import { APP_CONFIG } from '@app/@config';
import { PostMessageType } from '@app/@shared/consts';
import { AuthService } from '@core/auth';
import { SsoService } from '@core/auth/services/sso.service';
import { ChangeLanguageService } from '@core/language';
import { SentryService } from '@core/sentry';
import { UpdateService } from '@core/update';
import { has } from '@shared/utils';

import { ChatMessageService } from './chat-message.service';
import { PostMessageRelocateService } from './post-message-relocate.service';

export interface ToastMessagePayload {
  message: string;
  title?: string;
  type?: string;
  asSnackbar?: boolean;
}

@Injectable()
export class PostMessageService {
  private readonly appConfig = inject(APP_CONFIG);

  constructor(
    private injector: Injector,
    private authService: AuthService,
    private router: Router,
    private changeLanguageService: ChangeLanguageService,
    private postMessageRelocateService: PostMessageRelocateService,
    private chatMessageService: ChatMessageService,
    private updateService: UpdateService,
    private sentryService: SentryService,
    private ssoService: SsoService,
  ) {}

  public initialize(): void {
    window.addEventListener('message', (event: MessageEvent) => this.receiveMessage(event), false);
  }

  public async receiveMessage(event: MessageEvent): Promise<void> {
    if (event.origin !== this.appConfig.iframeHref) {
      return;
    }

    try {
      const message: any = JSON.parse(event.data);
      await this._handleMessage(message);
    } catch (e) {
      console.warn(e);
      //skip invalid message
    }
  }

  private async _handleMessage(message: any): Promise<void> {
    if (message.hasOwnProperty('type') && message.hasOwnProperty('body')) {
      const type: PostMessageType = message.type;
      const payload: any = message.body;

      switch (type) {
        case PostMessageType.LOG_IN:
          await this._authenticate(payload);
          break;
        case PostMessageType.LOG_IN_ERROR:
          await this._redirectToSsoLoginPage();
          break;
        case PostMessageType.LOG_OUT:
          await this._logout(payload);
          break;
        case PostMessageType.LANGUAGE:
          this._changeLanguage(payload);
          break;
        case PostMessageType.RELOCATE:
          this._changeLocation(payload);
          break;
        case PostMessageType.NEW_CHAT_MESSAGE_COUNT:
          this._newChatMessagesCount(payload);
          break;
        case PostMessageType.TOAST_MESSAGE:
          this._showToastMessage(payload);
          break;
        case PostMessageType.NO_ACCESS:
          this._handleNoAccess();
          break;
      }
    }
  }

  private async _authenticate(payload: any): Promise<void> {
    try {
      await this.authService.authenticateByJWTParams({
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      });
      await this.router.navigate(['/'], { replaceUrl: true });
    } catch (error) {
      //To production debug only - why invalid token payload received from postmessage
      //https://sentry.inelo.pl/organizations/sentry/issues/921302/?project=19
      this.sentryService.captureException(error, { payload: JSON.stringify(payload) });
    } finally {
      if (this.updateService.isUpdateAvailable()) {
        this.updateService.updateApp();
      }
    }
  }

  private _logout(_payload: any): Promise<void> {
    return this.authService.logout();
  }

  private _changeLanguage(payload: any): void {
    this.changeLanguageService.setLanguage(payload.lang);
  }

  private _changeLocation(payload: any): void {
    this.postMessageRelocateService.handleLocation(
      payload.location,
      has(payload, 'newWindow') ? payload.newWindow : false,
    );
  }

  private _newChatMessagesCount(payload: any): void {
    this.chatMessageService.setUnreadMessageCount(payload.newMessageCount);
  }

  private get toastService(): ToastService {
    return this.injector.get(ToastService);
  }

  private _showToastMessage(payload: ToastMessagePayload): void {
    if (!(payload && typeof payload.message === 'string')) {
      return;
    }

    const title: string = typeof payload.title === 'string' ? payload.title : null;

    if (payload.asSnackbar === true) {
      this.toastService.show(payload.message, title, {
        positionClass: 'snackbar',
      });
      return;
    }

    switch (payload.type) {
      case 'error':
        this.toastService.error(payload.message, title);
        return;
      case 'warning':
        this.toastService.warning(payload.message, title);
        return;
      case 'info':
        this.toastService.info(payload.message, title);
        return;
      case 'success':
        this.toastService.success(payload.message, title);
        return;
      default:
        this.toastService.show(payload.message, title);
        return;
    }
  }

  private _handleNoAccess(): void {
    const path: string = document.location.pathname;
    const segments: string[] = path.split('/').filter((segment: string) => segment.length > 0);
    const currentRouteSegment: string = segments[0];
    const authOutletRoute: Route = this.router.config.find((x: Route): boolean => x.path === '');

    if (!authOutletRoute) {
      this.router.navigate(['/no-access']);
      return;
    }

    const route: Route = authOutletRoute.children?.find((x: Route): boolean => x.path === currentRouteSegment);

    if (!route) {
      this.router.navigate(['/no-access']);
      return;
    }

    if (route.hasOwnProperty('children') || route.hasOwnProperty('loadChildren')) {
      this.router.navigate(['/', route.path, 'no-access']);
      return;
    }

    this.router.navigate(['/no-access']);
  }

  private async _redirectToSsoLoginPage(): Promise<void> {
    return this.ssoService.redirectToLoginPage();
  }
}
