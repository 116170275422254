import { APP_INITIALIZER, NgModule } from '@angular/core';

import { GoogleTagManagerService } from './google-tag-manager.service';
import { TagManagerScriptService } from './tag-manager-script.service';

@NgModule({
  providers: [
    GoogleTagManagerService,
    TagManagerScriptService,
    {
      provide: APP_INITIALIZER,
      useFactory: (tagManagerService: GoogleTagManagerService) => () => tagManagerService.initialize(),
      deps: [GoogleTagManagerService],
      multi: true,
    },
  ],
})
export class GoogleTagManagerModule {}
