import { CurrentUser } from '@core/auth';
import { UserPermissions } from '@shared/model/user-permissions.model';

import { UserResponse } from '../types/api.type';

export function userSerializer(response: UserResponse): CurrentUser {
  const data: UserResponse['user'] = response.user;
  const permissions: UserPermissions = new UserPermissions(data.accessTypes.GBOX_ONLINE);

  return new CurrentUser(
    data.id,
    data.isPanelAdmin,
    data.username,
    data.firstName,
    data.lastName,
    data.phoneNumber,
    data.email,
    permissions,
    data.isExpired ?? false,
  );
}
