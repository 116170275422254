import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Client, JWTSession, CurrentUser, AUTH_SESSION, Session } from '@core/auth';
import { clientSerializer } from '@core/auth/api/serializers';
import { SsoService } from '@core/auth/services/sso.service';
import { ClientGQL, ClientQuery } from '@shared/graphql';
import { LocalStorageKeys, LocalStorageService } from '@shared/local-storage';

import { AuthApiService } from '../api/services/api.service';
import { AuthServiceI, JWTSessionParams } from '../types';

@Injectable()
export class AuthService implements AuthServiceI {
  private isInitialized: boolean = false;
  private _session: BehaviorSubject<JWTSession | null> = new BehaviorSubject(null);
  private _currentUser: BehaviorSubject<CurrentUser | null> = new BehaviorSubject(null);
  private _client: BehaviorSubject<Client | null> = new BehaviorSubject(null);
  private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private localStorageService: LocalStorageService,
    private authApiService: AuthApiService,
    private router: Router,
    private clientGQL: ClientGQL,
    private ssoService: SsoService,
    @Optional() @Inject(AUTH_SESSION) private sessions: Session[],
  ) {}

  public get isAuthenticated(): boolean {
    return this._isAuthenticated.value;
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated.asObservable();
  }

  public get session(): null | JWTSession {
    return this._session.value;
  }

  public get currentUser(): null | CurrentUser {
    return this._currentUser.value;
  }

  public get client(): null | Client {
    return this._client.value;
  }

  public get session$(): Observable<null | JWTSession> {
    return this._session.asObservable();
  }

  public get currentUser$(): Observable<null | CurrentUser> {
    return this._currentUser.asObservable();
  }

  public get client$(): Observable<null | Client> {
    return this._client.asObservable();
  }

  public async initialize(): Promise<void> {
    if (this.isInitialized) {
      throw new Error('Service is already initialized');
    }

    this.localStorageService.registerCallback(LocalStorageKeys.Session, (event: StorageEvent) => {
      const loggedIn: boolean = event.oldValue === null && event.newValue !== null;
      const loggedOut: boolean = event.newValue === null && event.oldValue !== null;
      if (loggedIn || loggedOut) {
        window.location.reload();
      }
    });

    await this.loadSessionFromLocalStorage();
    this.isInitialized = true;
  }

  public async authenticateByJWTSession(session: JWTSession): Promise<void> {
    if (this.isAuthenticated) {
      throw new Error('Session exists, invalidate current session first');
    }

    try {
      await session.setup();

      this._session.next(session);
      this.handleSessionEvents(session);

      const currentUser: CurrentUser = await firstValueFrom(this.authApiService.getCurrentUser());
      const client: Client = await firstValueFrom(this.getClient());

      this._currentUser.next(currentUser);
      this._client.next(client);
      this._isAuthenticated.next(true);
      this.localStorageService.setItem(LocalStorageKeys.Session, this.serializeSession(session));
    } catch (error) {
      await this.destroyState();
      throw error;
    }
  }

  public authenticateByJWTParams(jwtSessionParams: JWTSessionParams): Promise<void> {
    const session: JWTSession = new JWTSession(
      jwtSessionParams.accessToken,
      jwtSessionParams.refreshToken,
      this.authApiService,
      jwtSessionParams.uuid,
    );

    return this.authenticateByJWTSession(session);
  }

  public async logout(): Promise<void> {
    if (this.isAuthenticated) {
      await this.destroyState();
      this.ssoService.redirectToLoginPage();
    }
  }

  private async loadSessionFromLocalStorage(): Promise<void> {
    const storageData: string = this.localStorageService.getItem(LocalStorageKeys.Session);

    if (storageData) {
      try {
        const jwtSessionParams: JWTSessionParams = this.deserializeSession(storageData);
        await this.authenticateByJWTParams(jwtSessionParams);
      } catch {
        await this.destroyState();
      }
    } else {
      try {
        await this.destroyIframeSession();
      } catch {}
    }
  }

  private async destroyState(): Promise<void> {
    try {
      await this.destroyIframeSession();
      await this.destroyAllLocalSessions();
    } catch {}

    this._isAuthenticated.next(false);

    if (this.session) {
      this.session.destroy();
    }

    this._session.next(null);
    this._currentUser.next(null);
    this._client.next(null);
    this.localStorageService.removeItem(LocalStorageKeys.Session);
  }

  private destroyAllLocalSessions(): Promise<void[]> {
    return Promise.all(
      (this.sessions || []).map(async (session: Session) => {
        try {
          await session.destroy().toPromise();
        } catch {}
      }),
    );
  }

  private destroyIframeSession(): Promise<boolean> {
    return this.authApiService.logoutUser().toPromise();
  }

  private handleSessionEvents(session: JWTSession): void {
    session.onDestroy$.subscribe(() => {
      this.logout();
    });

    session.onRefresh$.subscribe((newState: JWTSession) => {
      this.localStorageService.setItem(LocalStorageKeys.Session, this.serializeSession(newState));
    });
  }

  private serializeSession(session: JWTSession): string {
    return JSON.stringify({
      accessToken: session.accessToken,
      refreshToken: session.refreshToken,
      uuid: session.uuid,
    });
  }

  private deserializeSession(data: string): JWTSessionParams {
    try {
      const { accessToken, refreshToken, uuid }: JWTSessionParams = JSON.parse(data);

      if (!!accessToken && !!refreshToken) {
        return { accessToken, refreshToken, uuid };
      }
    } catch {}

    throw new Error('invalid session data');
  }

  private getClient(): Observable<Client> {
    return this.clientGQL
      .fetch({})
      .pipe(map((clientQuery: ApolloQueryResult<ClientQuery>): Client => clientSerializer(clientQuery.data)));
  }
}
