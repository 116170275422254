import { TypedTypePolicies } from '../generated';

export const MapModuleSettings: TypedTypePolicies['MapModuleSettings'] = {
  merge: true,
  fields: {
    userSettings: {
      merge: true,
    },
  },
};
