import { NgModule } from '@angular/core';
import { TooltipModule as TooltipModule2, TooltipOptions } from '@cloudfactorydk/ng2-tooltip-directive';
import {
  DropdownModule,
  ModalModule,
  PopoverModule,
  TabsModule,
  ToastModule,
  TooltipModule,
  WavesModule,
} from 'ng-uikit-pro-standard';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  imports: [
    ToastModule.forRoot(),
    TooltipModule.forRoot(),
    DropdownModule.forRoot(),
    WavesModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule2.forRoot({} as TooltipOptions),
    NgxPermissionsModule.forRoot(),
    ModalModule.forRoot(),
  ],
})
export class LibsModule {}
