import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig, APP_CONFIG } from '@app/@config';
import { AuthService } from '@core/auth';
import { environment } from 'src/environments/environment';

import { HttpInterceptorHelper } from '../http-interceptor-helper';

@Injectable()
export class InjectMonitoringHeadersInterceptor implements HttpInterceptor {
  private readonly appConfig: AppConfig = inject(APP_CONFIG);

  private sessionIDHeader: string = this.appConfig.monitoringHeaderSessionID;
  private appVersionHeader: string = this.appConfig.monitoringHeaderAppVersion;
  private title: string = this.appConfig.title;
  private authService: AuthService;

  constructor(private injector: Injector, private interceptorHelper: HttpInterceptorHelper) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.interceptorHelper.isJwtAuthHost(request)) {
      return next.handle(request);
    }

    if (!this.authService) {
      this.authService = this.injector.get(AuthService);
    }

    let headers: HttpHeaders = request.headers || new HttpHeaders();

    if (this.appVersionHeader) {
      headers = headers.set(this.appVersionHeader, `${this.title}/${environment.tag}`);
    }

    if (this.authService.isAuthenticated && !!this.sessionIDHeader) {
      headers = headers.set(this.sessionIDHeader, this.authService.session?.uuid);
    }

    return next.handle(request.clone({ headers }));
  }
}
