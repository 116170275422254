import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';

import { AuthService } from '@core/auth';

@Injectable({
  providedIn: 'root',
})
export class IsUnauthorizedGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable((resolve: Subscriber<boolean>) => {
      const forceLogout: boolean = route.queryParamMap.has('forceLogout');

      if (this.authService.isAuthenticated && !forceLogout) {
        this.router.navigate(['/', 'map']);
        resolve.next(false);
      } else if (this.authService.isAuthenticated && forceLogout) {
        this.authService.logout().then(() => resolve.next(true));
      } else if (!this.authService.isAuthenticated && forceLogout) {
        this.router.navigate([], { queryParams: null });
        resolve.next(true);
      } else {
        resolve.next(true);
      }
    });
  }
}
