export enum GoogleTagManagerEvent {
  SetUserContext = 'setUserContext',
  PageView = 'pageView',

  //Map
  MapRouteIncidentClick = 'map__routeIncidentClick',
  MapDisplayEtaTooltip = 'map__displayEtaTooltip',

  // Digital Reads
  DigitalReadsSortTable = 'digitalReads__sortTable',
  DigitalReadsDownloadFiles = 'digitalReads__downloadFiles',
  DigitalReadsMakeAdditionalReads = 'digitalReads__makeAdditionalReads',
  DigitalReadsToggleShowSuspendedReads = 'digitalReads__toggleShowSuspendedReads',
  DigitalReadsDisplayTooltip = 'digitalReads__displayTooltip',
  DigitalReadsConfigurationSuccess = 'digitalReads__configurationSuccess',
  DigitalReadsSaveNewCompanyCard = 'digitalReads__saveNewCompanyCard',
}
