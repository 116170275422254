import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '@core/auth';

@Injectable({
  providedIn: 'root',
})
export class IsAuthorizedGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(): boolean {
    if (this.authService.isAuthenticated) {
      return true;
    }

    this.authService.logout().finally(() => {
      this.router.navigate(['/login']);
    });

    return false;
  }
}
