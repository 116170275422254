import { Injectable, OnDestroy } from '@angular/core';

import { LocalStorageKeys } from './local-storage-keys.enum';

interface ICallbackWrapper {
  id: number;
  func: Function;
}

export interface ILocalStorageService {
  registerCallback(key: LocalStorageKeys, callBack: Function): number;

  unregisterCallback(key: LocalStorageKeys, callbackId: number): void;

  setObject(key: string | LocalStorageKeys, value: object): void;

  getObject(key: string | LocalStorageKeys): any | null;

  getObjectWithDefault(key: string | LocalStorageKeys, defaultValue: any): any;

  setItem(key: string | LocalStorageKeys, value: string): void;

  getItem(key: string | LocalStorageKeys): any | null;

  removeItem(key: string | LocalStorageKeys): void;

  clear(): void;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements OnDestroy {
  private callbackCounts: number = 0;
  private readonly callbacks: { [index: string]: ICallbackWrapper[] } = {};

  constructor() {
    window.addEventListener('storage', this.handleLocalStorageChange);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('storage', this.handleLocalStorageChange);
    Object.keys(this.callbacks).map((index: string) => (this.callbacks[index] = []));
  }

  public registerCallback(key: LocalStorageKeys, callBack: Function): number {
    this.callbackCounts += 1;

    if (!this.callbacks[key]) {
      this.callbacks[key] = [];
    }

    this.callbacks[key].push({
      id: this.callbackCounts,
      func: callBack,
    });

    return this.callbackCounts;
  }

  public unregisterCallback(key: LocalStorageKeys, callbackId: number): void {
    if (!this.callbacks[key]) {
      return;
    }

    const index: number = this.callbacks[key].findIndex((wrapper: ICallbackWrapper) => wrapper.id === callbackId);

    if (index < 0) {
      return;
    }

    this.callbacks[key].splice(index, 1);
  }

  public setObject(key: string | LocalStorageKeys, value: object): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getObject(key: string | LocalStorageKeys): any | null {
    const data: string = localStorage.getItem(key);

    return data !== null ? JSON.parse(data) : null;
  }

  public getObjectWithDefault(key: string | LocalStorageKeys, defaultValue: any = null): any {
    const data: any = this.getObject(key);

    return data !== null ? data : defaultValue;
  }

  public setItem(key: string | LocalStorageKeys, value: string): void {
    localStorage.setItem(key, value);
  }

  public getItem(key: string | LocalStorageKeys): any | null {
    return localStorage.getItem(key);
  }

  public removeItem(key: string | LocalStorageKeys): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    localStorage.clear();
  }

  private handleLocalStorageChange = (event: StorageEvent): void => {
    if (this.callbacks[event.key]) {
      this.callbacks[event.key].map((wrapper: ICallbackWrapper) => wrapper.func(event));
    }
  };
}
