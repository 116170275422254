import { TypedTypePolicies } from '../generated';

export const Eta: TypedTypePolicies['Eta'] = {
  fields: {
    createdAt: {
      read(data) {
        return data ? new Date(data) : null;
      },
    },
    updatedAt: {
      read(data) {
        return data ? new Date(data) : null;
      },
    },
  },
};
