import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SentryService } from '@core/sentry';
import { LocalStorageModule } from '@shared/local-storage';
import { environment } from 'src/environments/environment';

import { ChangeLanguageService } from './change-language.service';
import '@angular/common/locales/global/pl';
import '@angular/common/locales/global/en';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.version}`);
}

export function initModule(changeLanguageService: ChangeLanguageService, sentryService: SentryService): Function {
  return async (): Promise<void> => {
    try {
      await changeLanguageService.initLanguage();
    } catch (e) {
      sentryService.captureException(e);
      console.error(e);
    }
  };
}

@NgModule({
  providers: [
    ChangeLanguageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initModule,
      deps: [ChangeLanguageService, SentryService],
      multi: true,
    },
  ],
  imports: [
    LocalStorageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
  ],
})
export class LanguageModule {}
