export enum PostMessageType {
  LOG_IN = 'logIn',
  LOG_IN_ERROR = 'logInError',
  LOG_OUT = 'logOut',
  LANGUAGE = 'lang',
  NEW_CHAT_MESSAGE_COUNT = 'newChatMessageCount',
  RELOCATE = 'relocate',
  SUBMENU_POSITION = 'submenuPosition',
  TOAST_MESSAGE = 'toastMessage',
  NO_ACCESS = 'noAccess',
}
