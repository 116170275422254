import { Client } from '@core/auth';
import { ClientQuery } from '@shared/graphql';

export function clientSerializer(data: ClientQuery): Client {
  return new Client(
    data.me.id,
    data.me.name,
    data.me.isInternal,
    data.me.isJoint ?? true,
    data.me.settings.legacy.licencePlan,
    data.me.settings.legacy,
    data.me.settings.system,
    data.me.featureFlags,
  );
}
