import { AvailableAccessTypes } from '@core/auth/api/types/api.type';

export type permissionKey = AvailableAccessTypes;
export type IUserPermissions = { [key in permissionKey]: ('r' | 'w')[] };

export class UserPermissions {
  private permissions: Partial<IUserPermissions> = {};

  constructor(data: IUserPermissions | null) {
    this.permissions = data ?? {};
  }

  public canReadOrWrite(module: permissionKey): boolean {
    return !!this.permissions[module]?.length;
  }

  public canRead(module: permissionKey): boolean {
    return !!this.permissions[module]?.includes('r');
  }

  public canWrite(module: permissionKey): boolean {
    return !!this.permissions[module]?.includes('w');
  }
}
