import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

// Check this issue of ngsw worker https://github.com/angular/angular/issues/36368
// Skip service worker precache to mutation requests
@Injectable()
export class PostNgswBypassInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET' && environment.production) {
      request = request.clone({ params: request.params.set('ngsw-bypass', 'true') });
    }

    return next.handle(request);
  }
}
