import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from 'src/environments/environment';

import * as fromGlobal from './global.reducer';

export interface State {
  global: fromGlobal.State;
}

export const reducers: ActionReducerMap<State> = {
  global: fromGlobal.reducer,
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [storeFreeze] : [];

export const getGlobalState = createFeatureSelector<State, fromGlobal.State>('global');

export const getAppView = createSelector(getGlobalState, fromGlobal.getAppView);
