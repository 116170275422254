/* eslint-disable import/no-duplicates */
// Nie importować za pomocą głównego scope date-fns,
// powoduje zaimportowanie wszystkiego z date-fns (sprawdź npm run analyze-bundle)
import addDays from 'date-fns/addDays';
import addMinutes from 'date-fns/addMinutes';
import addMonths from 'date-fns/addMonths';
import dFormat from 'date-fns/format';
import dFormatDistanceToNow from 'date-fns/formatDistanceToNow';
import { intlFormatDistanceWithOptions } from 'date-fns/fp';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isValid from 'date-fns/isValid';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subSeconds from 'date-fns/subSeconds';

import { config } from './date.config';
import { AppDateType, IsBetweenInclusivity } from './date.types';

export function now(): AppDateType {
  return new Date();
}

export function format(source: AppDateType, dateFormat: string): string {
  return dFormat(source, dateFormat, { locale: config.locale });
}

export function isBetween(
  date: AppDateType,
  from: AppDateType,
  to: AppDateType,
  inclusivity: IsBetweenInclusivity = '()',
): boolean {
  if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
    throw new Error('Inclusivity parameter must be one of (), [], (], [)');
  }

  const isBeforeEqual: boolean = inclusivity[0] === '[';
  const isAfterEqual: boolean = inclusivity[1] === ']';

  return (
    (isBeforeEqual ? isEqual(from, date) || isBefore(from, date) : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
}

export function formatDistanceToNow(date: AppDateType, options?: Parameters<typeof dFormatDistanceToNow>[1]): string {
  return dFormatDistanceToNow(date, { locale: config.locale, ...options });
}

export function intlFormatDistance(
  date: AppDateType,
  options?: Parameters<typeof intlFormatDistanceWithOptions>[0],
): string {
  return intlFormatDistanceWithOptions({ locale: config.locale.code, ...options }, now(), date);
}
export {
  addDays,
  addMinutes,
  addMonths,
  isAfter,
  isToday,
  subHours,
  isEqual,
  isTomorrow,
  subSeconds,
  subDays,
  isValid,
};
