import { inject, Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@app/@config';
import { AuthService } from '@core/auth';
import { NumberLimiterPipe } from '@shared/pipes/number-limiter/number-limiter.pipe';

@Injectable()
export class ChatMessageService implements OnDestroy {
  private _unreadMessageCount: Subject<number> = new Subject<number>();
  private unsubscribe$: Subject<void> = new Subject<void>();
  private readonly appConfig: AppConfig = inject(APP_CONFIG);

  constructor(
    private titleService: Title,
    private numberLimiterPipe: NumberLimiterPipe,
    private authService: AuthService,
  ) {
    this.authService.isAuthenticated$.pipe(takeUntil(this.unsubscribe$)).subscribe((isLogged: boolean) => {
      if (isLogged) {
        return;
      }

      this.titleService.setTitle(this.appConfig.title);
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public setUnreadMessageCount(count: number): void {
    this._unreadMessageCount.next(count);
    this.addUnreadMessageCountToTitle(count);
  }

  private addUnreadMessageCountToTitle(count: number): void {
    let title: string = this.appConfig.title;
    const limitedCount: string = this.numberLimiterPipe.transform(count);

    if (count > 0) {
      title = `${title} (${limitedCount})`;
    }

    this.titleService.setTitle(title);
  }

  public get unreadMessageCount$(): Observable<number> {
    return this._unreadMessageCount.asObservable();
  }
}
