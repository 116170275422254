import { DriverCardCountryCodes } from '@shared/consts';
import { TypedTypePolicies } from '@shared/graphql';
import { Option } from '@shared/model/common';

export const DriverCard: TypedTypePolicies['DriverCard'] = {
  fields: {
    countryCodeLabel: {
      read(_, { readField }): string {
        return (
          DriverCardCountryCodes.find((code: Option<number>) => code.value === readField('countryCode'))?.label ?? ''
        );
      },
    },
  },
};
