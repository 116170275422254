import { Reference } from '@apollo/client';

import { DriverCardPredefinedNumbers } from '@app/@shared/consts';

import { DriverState, TypedTypePolicies } from '../generated';

export const Driver: TypedTypePolicies['Driver'] = {
  fields: {
    fullName: {
      read(_, { readField }): string {
        return `${readField('lastName')} ${readField('firstName')}`.trim();
      },
    },
    driverState: {
      read(_, { readField }): DriverState {
        const driverIdentificationNumber = readField('identificationId');

        if (driverIdentificationNumber === DriverCardPredefinedNumbers.Unauthorized) {
          return DriverState.UNAUTHORIZED;
        } else if (driverIdentificationNumber == null) {
          return DriverState.NO_DATA;
        }

        return DriverState.IDENTIFIED;
      },
    },
    identificationId: {
      read(_, { readField }): string {
        const driverCardRef: Reference = readField('driverCard');
        const driverIdentificationNumber: string | undefined = readField('driverIdentificationNumber') || null;

        return driverCardRef
          ? readField('number', driverCardRef) || driverIdentificationNumber
          : driverIdentificationNumber;
      },
    },
    isAuthorized: {
      read(_, { readField }): boolean {
        const state: DriverState = readField('driverState');

        return state ? [DriverState.IDENTIFIED, DriverState.UNRECOGNIZED_ID_NUMBER].includes(state) : null;
      },
    },
  },
};
