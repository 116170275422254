import { HttpResponse } from '@angular/common/http';

export class BlobResponse {
  public static showFileSavingWindow(response: HttpResponse<Blob | ArrayBuffer>, fileName?: string): void {
    const blob: Blob = new Blob([response.body]);

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = BlobResponse.getFileNameFromHttpResponse(response, fileName);
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  }

  private static getFileNameFromHttpResponse(response: HttpResponse<Blob | ArrayBuffer>, fileName?: string): string {
    const contentDispositionHeader: string = response.headers.get('content-disposition');

    if (fileName) {
      const contentDispositionExtension: string = contentDispositionHeader.split('.').slice(-1).toString();

      return fileName.includes('.')
        ? fileName
        : contentDispositionExtension
        ? `${fileName}.${contentDispositionExtension}`
        : fileName;
    }

    if (contentDispositionHeader) {
      return contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '');
    }

    return '';
  }
}
