import { HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { AppConfig, APP_CONFIG } from '@app/@config';

@Injectable()
export class HttpInterceptorHelper {
  private readonly appConfig: AppConfig = inject(APP_CONFIG);

  public isRefreshRequest(request: HttpRequest<any>): boolean {
    return request.url === this.appConfig.refreshTokenUrl && request.method === 'GET';
  }

  public isJwtAuthHost(request: HttpRequest<any>): boolean {
    const jwtAuthHosts: Array<string> = this.appConfig.jwtAuthHosts;
    let matches: Array<string> = [];

    if (jwtAuthHosts.filter) {
      matches = jwtAuthHosts.filter((value: string) => request.url.indexOf(value) >= 0);
    }

    return matches.length > 0;
  }

  public addToken(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: 'Bearer ' + accessToken } });
  }
}
