import { TypedTypePolicies } from '../generated';

import { EtaMonitor } from './eta-monitor.type-policy';

export const EtaLightTask: TypedTypePolicies['EtaLightTask'] = {
  fields: {
    ...EtaMonitor.fields,
    plannedArrivalTime: {
      read(value: string): Date {
        return value ? new Date(value) : null;
      },
    },
    createdAt: {
      read(value: string): Date {
        return value ? new Date(value) : null;
      },
    },
    updateAt: {
      read(value: string): Date {
        return value ? new Date(value) : null;
      },
    },
  },
};
