import { SUPPORTED_LANGUAGE } from '@shared/consts/supported-language.enum';

export function languageCodeToAcceptLanguageHeaderCode(langISOCode: string): string {
  const definition: { [key in SUPPORTED_LANGUAGE]: string } = {
    pl: 'pl-PL',
    en: 'en-GB',
  };

  return definition[langISOCode] || definition.pl;
}

export async function restDirectiveResponseTransformer(response: Response): Promise<any> {
  try {
    const responseText = await response.text();
    const responseDataLength = responseText.length;

    if (responseDataLength > 0) {
      return JSON.parse(responseText);
    }

    return {};
  } catch (_e) {
    return {};
  }
}
