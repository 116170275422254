import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProtectedConfigResolver, ProtectedConfigRouteConfig } from '@app/@config';
import { IsAuthorizedGuard, IsUnauthorizedGuard } from '@shared/guards';
import { NotFoundPage } from '@shared/pages/not-found';

const appRoutes: Routes = [
  {
    path: 'login',
    canActivate: [IsUnauthorizedGuard],
    loadChildren: () => import('./@modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'profile',
    canActivate: [IsUnauthorizedGuard],
    loadChildren: () => import('./@modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('./@modules/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: '',
    canActivate: [IsAuthorizedGuard],
    loadChildren: () => import('./@modules/panel/panel.module').then((m) => m.PanelModule),
    ...ProtectedConfigRouteConfig,
  },
  { path: '**', component: NotFoundPage },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [ProtectedConfigResolver],
})
export class AppRoutingModule {}
