import { DOCUMENT } from '@angular/common';
import { inject, Inject, Injectable } from '@angular/core';
import Hotjar from '@hotjar/browser';

import { APP_CONFIG, AppConfig } from '@app/@config';
import { HotjarParameters } from '@core/hotjar/hotjar.model';

@Injectable({ providedIn: 'root' })
export class HotjarService {
  private readonly appConfig: AppConfig = inject(APP_CONFIG);
  private hotjarSiteId: number = this.appConfig.hotjarSiteId;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.initialize();
  }

  public isReady(): boolean {
    return Hotjar.isReady();
  }

  public initialize(): void {
    if (this.hotjarSiteId) {
      Hotjar.init(this.hotjarSiteId, 6);
    }
  }

  public update(userId: number, params: HotjarParameters): void {
    Hotjar.identify(userId.toString(), { ...params });
  }
}
