import { booleanSerializer, numberSerializer } from '@shared/graphql/type-policies/utils/serializer';

import { TypedTypePolicies } from '../generated';

export const MapModuleClientSettings: TypedTypePolicies['MapModuleClientSettings'] = {
  fields: {
    hiSpeedBig: numberSerializer,
    hiSpeedSmall: numberSerializer,
    lowSpeedBig: numberSerializer,
    lowSpeedSmall: numberSerializer,
    showStopAfter: numberSerializer,
    transparentLblBgColor: numberSerializer,
    layingLabels: booleanSerializer,
    rosaVentorum: booleanSerializer,
    unifyLabelsColor: {
      read(value): boolean {
        return typeof value === 'boolean' ? value : !booleanSerializer.read(value);
      },
    },
  },
};
