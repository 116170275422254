import { Option } from '@shared/model/common';

export enum DriverCardCountryCode {
  A = 1,
  AL = 2,
  AND = 3,
  ARM = 4,
  AZ = 5,
  B = 6,
  BG = 7,
  BIH = 8,
  BY = 9,
  CH = 10,
  CY = 11,
  CZ = 12,
  D = 13,
  DK = 14,
  E = 15,
  EST = 16,
  F = 17,
  FIN = 18,
  FL = 19,
  FR = 20,
  UK = 21,
  GE = 22,
  GR = 23,
  H = 24,
  HR = 25,
  I = 26,
  IRL = 27,
  IS = 28,
  KZ = 29,
  L = 30,
  LT = 31,
  LV = 32,
  M = 33,
  MC = 34,
  MD = 35,
  MK = 36,
  N = 37,
  NL = 38,
  P = 39,
  PL = 40,
  RO = 41,
  RSM = 42,
  RUS = 43,
  S = 44,
  SK = 45,
  SLO = 46,
  TM = 47,
  TR = 48,
  UA = 49,
  V = 50,
  YU = 51,
  MNE = 52,
  SRB = 53,
  UZ = 54,
  TJ = 55,
  EC = 253,
  EUR = 254,
  WLD = 255,
}

export const DriverCardCountryCodes: Option<number>[] = [
  { value: 1, label: 'A' },
  { value: 2, label: 'AL' },
  { value: 3, label: 'AND' },
  { value: 4, label: 'ARM' },
  { value: 5, label: 'AZ' },
  { value: 6, label: 'B' },
  { value: 7, label: 'BG' },
  { value: 8, label: 'BIH' },
  { value: 9, label: 'BY' },
  { value: 10, label: 'CH' },
  { value: 11, label: 'CY' },
  { value: 12, label: 'CZ' },
  { value: 13, label: 'D' },
  { value: 14, label: 'DK' },
  { value: 15, label: 'E' },
  { value: 16, label: 'EST' },
  { value: 17, label: 'F' },
  { value: 18, label: 'FIN' },
  { value: 19, label: 'FL' },
  { value: 20, label: 'FR' },
  { value: 21, label: 'UK' },
  { value: 22, label: 'GE' },
  { value: 23, label: 'GR' },
  { value: 24, label: 'H' },
  { value: 25, label: 'HR' },
  { value: 26, label: 'I' },
  { value: 27, label: 'IRL' },
  { value: 28, label: 'IS' },
  { value: 29, label: 'KZ' },
  { value: 30, label: 'L' },
  { value: 31, label: 'LT' },
  { value: 32, label: 'LV' },
  { value: 33, label: 'M' },
  { value: 34, label: 'MC' },
  { value: 35, label: 'MD' },
  { value: 36, label: 'MK' },
  { value: 37, label: 'N' },
  { value: 38, label: 'NL' },
  { value: 39, label: 'P' },
  { value: 40, label: 'PL' },
  { value: 41, label: 'RO' },
  { value: 42, label: 'RSM' },
  { value: 43, label: 'RUS' },
  { value: 44, label: 'S' },
  { value: 45, label: 'SK' },
  { value: 46, label: 'SLO' },
  { value: 47, label: 'TM' },
  { value: 48, label: 'TR' },
  { value: 49, label: 'UA' },
  { value: 50, label: 'V' },
  { value: 51, label: 'YU' },
  { value: 52, label: 'MNE' },
  { value: 53, label: 'SRB' },
  { value: 54, label: 'UZ' },
  { value: 55, label: 'TJ' },
  { value: 253, label: 'EC' },
  { value: 254, label: 'EUR' },
  { value: 255, label: 'WLD' },
];
