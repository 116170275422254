import { TypedTypePolicies } from '../generated';

export const VehicleCurrentType: TypedTypePolicies['VehicleCurrentType'] = {
  fields: {
    lastSignal: {
      read(value) {
        return value ? new Date(value) : value;
      },
    },
  },
};
