import { ApplicationRef, ComponentRef, enableProdMode, NgModuleRef } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { APP_CONFIG, AppConfigLoader } from '@app/@config';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

AppConfigLoader.load().then((config) => {
  platformBrowserDynamic([{ provide: APP_CONFIG, useValue: Object.freeze({ ...config }) }])
    .bootstrapModule(AppModule)
    .then((module: NgModuleRef<AppModule>) => {
      if (!environment.production) {
        const applicationRef: ApplicationRef = module.injector.get(ApplicationRef);
        const appComponent: ComponentRef<any> = applicationRef.components[0];
        enableDebugTools(appComponent);
      }
    })
    .catch(console.error);
});
