import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

import { SUPPORTED_LANGUAGE } from '@shared/consts';

import { config } from './date.config';
import { enGB, pl } from './date.locale';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private locale$: BehaviorSubject<Locale> = new BehaviorSubject(pl);

  constructor(private translateService: TranslateService) {}

  public get onLocaleChange$(): Observable<Locale> {
    return this.locale$.asObservable();
  }

  public get locale(): Locale {
    return this.locale$.value;
  }

  public initialize(): void {
    this.setLocale(this.translateService.currentLang);

    this.translateService.onLangChange.subscribe(() => {
      this.setLocale(this.translateService.currentLang);
    });
  }

  private setLocale(locale: string): void {
    moment.locale(locale);

    if (locale === SUPPORTED_LANGUAGE.EN) {
      config.locale = enGB;
    } else {
      config.locale = pl;
    }

    this.locale$.next(config.locale);
  }
}
