import {
  FeatureFlagsType,
  FleetTypeType as FleetType,
  Legacy,
  LicencePlanType as LicencePlan,
  System as SystemSettings,
} from '@shared/graphql';

export class Client {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly isInternal: boolean,
    public readonly isJoint: boolean,
    public readonly licencePlan: LicencePlan,
    public readonly legacy: Legacy,
    public readonly moduleConfig: SystemSettings,
    private readonly _featureFlags: FeatureFlagsType = null,
  ) {}

  public get fleetType(): FleetType {
    return this.moduleConfig.fleet?.type;
  }

  public getModuleConfig<T extends keyof SystemSettings>(module: T): SystemSettings[T] | null {
    return this.moduleConfig[module];
  }

  public moduleEnabled<T extends keyof SystemSettings>(module: T): boolean {
    return !!(this.getModuleConfig(module) as any)?.enabled;
  }

  public hasOBUFleet(): boolean {
    return this.moduleConfig.fleet?.type === FleetType.OBU;
  }

  public hasGboxFleet(): boolean {
    return this.moduleConfig.fleet?.type === FleetType.OBU;
  }

  public get hasMixedFleet(): boolean {
    return this.moduleConfig.fleet?.type === FleetType.MIXED;
  }

  public get hasAssist(): boolean {
    return this.moduleConfig.fleet?.hasAssist;
  }

  public get featureFlags(): FeatureFlagsType | null {
    return this._featureFlags;
  }

  public hasTachoRemoteFeatureEnabled(): boolean {
    return this._featureFlags?.tachoRemote ?? true;
  }
}
