import { APP_INITIALIZER, NgModule } from '@angular/core';

import { DateService } from './date.service';

export function initModule(dateService: DateService): Function {
  return async (): Promise<void> => dateService.initialize();
}

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initModule,
      deps: [DateService],
      multi: true,
    },
  ],
})
export class DateModule {}
