import { ChangeDetectorRef, Component, inject, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationError, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@app/@config';
import * as fromRoot from '@app/store/reducers';
import { UpdateService } from '@core/update';

import { AppService, ON_APP_INIT } from './app.service';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public showUpdateModal: boolean = false;
  public iframeHref: string;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private readonly appConfig: AppConfig = inject(APP_CONFIG);

  constructor(
    private router: Router,
    private titleService: Title,
    private zone: NgZone,
    private updateService: UpdateService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private store: Store<fromRoot.State>,
    private appService: AppService,
    @Inject(ON_APP_INIT) private onAppInit: Subject<void>,
  ) {
    this.zone.runOutsideAngular(() => {
      window.document.addEventListener('click', (event: Event): void => {
        event.stopPropagation();
      });
    });

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: RouterEvent) => {
      if (event instanceof RouteConfigLoadStart && this.updateService.isUpdateAvailable()) {
        if (event.hasOwnProperty('loadChildren')) {
          window.location.href = event.url;
        }
      }

      if (event instanceof NavigationError) {
        if (event.error.name === 'ChunkLoadError') {
          window.location.href = event.url;
        }
      }
    });

    this.iframeHref = this.appConfig.iframeHref;
  }

  public ngOnInit(): void {
    this.onAppInit.next();

    this.updateService.isUpdateAvailable$().subscribe((isUpdateAvailable: boolean) => {
      this.showUpdateModal = isUpdateAvailable;
      this.changeDetector.detectChanges();
    });

    this.titleService.setTitle(this.appConfig.title);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
