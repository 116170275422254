import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '@core/auth';

import { HttpInterceptorHelper } from '../http-interceptor-helper';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private interceptorHelper: HttpInterceptorHelper) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.interceptorHelper.isRefreshRequest(request) || !this.interceptorHelper.isJwtAuthHost(request)) {
      return next.handle(request);
    }

    const authService: AuthService = this.injector.get(AuthService);

    if (authService.session?.accessToken) {
      return next.handle(this.interceptorHelper.addToken(request, authService.session.accessToken));
    }

    return next.handle(request);
  }
}
