import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from 'ng-uikit-pro-standard';

import { UpdateModalComponent } from './components/update-modal/update-modal.component';
import { UpdateService } from './update.service';

@NgModule({
  declarations: [UpdateModalComponent],
  imports: [CommonModule, TranslateModule, ButtonsModule],
  providers: [
    UpdateService,
    {
      provide: APP_INITIALIZER,
      useFactory: (updateService: UpdateService) => () => updateService.startWatching(),
      deps: [UpdateService],
      multi: true,
    },
  ],
  exports: [UpdateModalComponent],
})
export class UpdateModule {}
