import { APP_INITIALIZER, NgModule } from '@angular/core';

import { NumberLimiterPipeModule } from '@shared/pipes/number-limiter/number-limiter.pipe.module';

import { ChatMessageService } from './chat-message.service';
import { MessageHandlerService } from './message-handler.service';
import { PostMessageRelocateService } from './post-message-relocate.service';
import { PostMessageService } from './post-message.service';

@NgModule({
  imports: [NumberLimiterPipeModule],
  providers: [
    MessageHandlerService,
    PostMessageService,
    PostMessageRelocateService,
    ChatMessageService,
    {
      provide: APP_INITIALIZER,
      deps: [PostMessageService],
      useFactory: (postMessageService: PostMessageService) => () => {
        postMessageService.initialize();
      },
      multi: true,
    },
  ],
})
export class IframeCommunicationModule {}
