import { APP_INITIALIZER, NgModule } from '@angular/core';

import { SsoService } from '@core/auth/services/sso.service';
import { LocalStorageModule } from '@shared/local-storage';

import { AuthApiService } from './api/services/api.service';
import { AuthService } from './services/auth.service';

@NgModule({
  imports: [LocalStorageModule],
  providers: [
    AuthService,
    AuthApiService,
    SsoService,
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () => authService.initialize(),
      deps: [AuthService],
      multi: true,
    },
  ],
})
export class AuthModule {}
