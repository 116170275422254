import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberLimiter',
})
export class NumberLimiterPipe implements PipeTransform {
  public static DEFAULT_SUFFIX: string = '+';
  public static DEFAULT_THRESHOLD: number = 99;

  public transform(
    value: number,
    threshold: number = NumberLimiterPipe.DEFAULT_THRESHOLD,
    suffix: string = NumberLimiterPipe.DEFAULT_SUFFIX,
  ): string {
    if (!value) {
      return '';
    }

    return value > threshold ? `${threshold}${suffix}` : value.toString();
  }
}
