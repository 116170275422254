import { TypedTypePolicies } from '../generated';

export const Task: TypedTypePolicies['Task'] = {
  fields: {
    eta: {
      merge: true,
    },
    arriveTimeEnd: {
      read(value: string): Date {
        return value ? new Date(value) : null;
      },
    },
  },
};
