import { APP_INITIALIZER, NgModule } from '@angular/core';

import { SentryService } from './sentry.service';

@NgModule({
  providers: [
    SentryService,
    {
      provide: APP_INITIALIZER,
      useFactory: (sentryService: SentryService) => () => sentryService.initialize(),
      deps: [SentryService],
      multi: true,
    },
  ],
})
export class SentryModule {}
