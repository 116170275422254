import { DOCUMENT } from '@angular/common';
import { inject, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { APP_CONFIG, AppConfig, BeamerConfig } from '@app/@config';
import { BeamerParameters } from '@core/beamer/beamer.model';

declare global {
  interface Window {
    beamer_config: BeamerConfig;
  }
}

@Injectable({ providedIn: 'root' })
export class BeamerService {
  private readonly appConfig: AppConfig = inject(APP_CONFIG);
  private readonly beamerConfig: BeamerConfig = this.appConfig.beamer;
  private _loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private get beamer(): any | undefined {
    return window['Beamer'];
  }

  constructor(@Inject(DOCUMENT) private document: Document, private translateService: TranslateService) {
    this.initialize();
  }

  public get loaded$(): Observable<boolean> {
    return this._loaded$.asObservable();
  }

  public init(): void {
    if (window.beamer_config?.user_id != null && window.beamer_config?.user_id !== '') {
      this.beamer?.init();
    }
  }

  public update(parameters: BeamerParameters): void {
    if (window.beamer_config?.user_id != null && window.beamer_config?.user_id !== '') {
      this.beamer?.update(parameters);
    }
  }

  public destroy(): void {
    this.beamer?.destroy();
  }

  public initialize(): void {
    if (!this.beamerConfigEnabled) return;
    this.registerBeamerInWindowScope();
    this.addBeamerScriptToBody();
  }

  private addBeamerScriptToBody(): void {
    const scriptTags = document.getElementsByTagName('noscript');
    const script = this.document.createElement('script');
    script.id = 'beamerScript';
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    script.defer = true;
    script.onload = () => {
      this._loaded$.next(true);
    };

    this.insertElementBefore(scriptTags, script);
  }

  private insertElementBefore(scriptTags, script): void {
    const lastScriptTag = scriptTags[scriptTags.length - 1];
    lastScriptTag.parentNode.insertBefore(script, lastScriptTag);
  }

  private registerBeamerInWindowScope(): void {
    window.beamer_config = { ...this.beamerConfig, language: this.translateService.currentLang };
  }

  private get beamerConfigEnabled(): boolean {
    return this.beamerConfig.enabled;
  }
}
