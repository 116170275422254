import { inject, Injectable, OnDestroy } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppConfig, APP_CONFIG } from '@app/@config';
import { get } from '@shared/utils';

import { ReloadMode } from './reload-mode.enum';

@Injectable()
export class UpdateService implements OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  private readonly _isUpdateAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly appConfig: AppConfig = inject(APP_CONFIG);
  private reloadMode: ReloadMode = null;

  constructor(private swUpdate: SwUpdate) {
    this.swUpdate.available.pipe(takeUntil(this.unsubscribe$)).subscribe((event: UpdateAvailableEvent) => {
      const reloadMode: ReloadMode = get(event, 'available.appData.reload', ReloadMode.SOFT);

      if (reloadMode === ReloadMode.SOFT || reloadMode === ReloadMode.HARD) {
        this.reloadMode = reloadMode;
      } else {
        this.reloadMode = ReloadMode.SOFT;
      }

      this._isUpdateAvailable$.next(true);
    });

    // TODO remove after test
    window['reloadTest'] = (test: any): void => {
      this.test(test);
    };
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public startWatching(): void {
    const intervalTime: number = this.appConfig.refreshIntervals.updateCheck || 3600000;

    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();

      const checkInterval$: Observable<number> = interval(intervalTime);

      checkInterval$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.swUpdate.checkForUpdate();
      });
    }
  }

  public isUpdateAvailable$(): Observable<boolean> {
    return this._isUpdateAvailable$.asObservable();
  }

  public isUpdateAvailable(): boolean {
    return this._isUpdateAvailable$.value;
  }

  public getReloadMode(): ReloadMode {
    return this.reloadMode;
  }

  public updateApp(): void {
    window.location.reload();
  }

  public test = (test: any): void => {
    this.reloadMode = test;
    this._isUpdateAvailable$.next(true);
  };
}
