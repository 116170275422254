import { DriverCardPredefinedNumbers } from '@app/@shared/consts';

import { DriverState, TypedTypePolicies } from '../generated';

export const DriverUnknown: TypedTypePolicies['DriverUnknown'] = {
  keyFields: ['driverCardNumber'],
  fields: {
    driverState: {
      read(_, { readField }): DriverState {
        if (readField('driverCardNumber') === DriverCardPredefinedNumbers.Unauthorized) {
          return DriverState.UNAUTHORIZED;
        }

        return DriverState.UNRECOGNIZED_ID_NUMBER;
      },
    },
    identificationId: {
      read(_, { readField }): string {
        return readField('driverCardNumber') || null;
      },
    },
    isAuthorized: {
      read(_, { readField }): boolean {
        const state: DriverState = readField('driverState');

        return state ? [DriverState.IDENTIFIED, DriverState.UNRECOGNIZED_ID_NUMBER].includes(state) : null;
      },
    },
  },
};
