import { TypedTypePolicies, VehicleDeviceCommunicationStatus } from '../generated';

export const VehicleDeviceType: TypedTypePolicies['VehicleDeviceType'] = {
  keyFields: false,
  fields: {
    isOffline: {
      read(_, { readField }): boolean | null {
        return readField('communicationStatus')
          ? readField('communicationStatus') === VehicleDeviceCommunicationStatus.OFFLINE
          : null;
      },
    },
  },
};
