import { NgModule } from '@angular/core';

import { NumberLimiterPipe } from './number-limiter.pipe';

@NgModule({
  declarations: [NumberLimiterPipe],
  exports: [NumberLimiterPipe],
  providers: [NumberLimiterPipe],
})
export class NumberLimiterPipeModule {}
