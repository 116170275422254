import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, pairwise } from 'rxjs';

import { AuthService } from '@core/auth';

import { GoogleTagManagerEvent } from './google-tag-manager.enum';
import { TagManagerScriptService } from './tag-manager-script.service';

@Injectable()
export class GoogleTagManagerService {
  private dataLayer = [];

  constructor(
    private readonly authService: AuthService,
    private readonly tagManagerScriptService: TagManagerScriptService,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    (this.document.defaultView as any).dataLayer = this.dataLayer;
  }

  public initialize(): void {
    this.tagManagerScriptService.init();
    this.setUserContext();
    this.handleRouteEvents();
  }

  public emitEvent(event: GoogleTagManagerEvent, data: Record<string, any> = {}): void {
    this.dataLayer.push({ event, timestamp: Date.now(), ...data });
  }

  private setUserContext(): void {
    this.authService.isAuthenticated$
      .pipe(pairwise(), distinctUntilChanged())
      .subscribe(([isPrevAuthenticated, isAuthenticated]) => {
        if (!isPrevAuthenticated && isAuthenticated) {
          this.emitEvent(GoogleTagManagerEvent.SetUserContext, {
            clientId: this.authService.client.id,
            fleetType: this.authService.client.fleetType,
            ecoDrivingType: this.authService.client.moduleConfig.ecoDriving.ranking ? 'pro' : 'basic',
            isInternalClient: this.authService.client.isInternal,
          });
        } else {
          this.emitEvent(GoogleTagManagerEvent.SetUserContext, {
            clientId: null,
            fleetType: null,
            ecoDrivingType: null,
            isInternalClient: null,
          });
        }
      });
  }

  public sendPageView(params: { pageTitle?: string; pagePath: string; pageLocation?: string }): void {
    this.emitEvent(GoogleTagManagerEvent.PageView, {
      pageTitle: params.pageTitle || this.document.title,
      pagePath: params.pagePath,
      pageLocation: params.pageLocation
        ? this.getPageLocation(params.pageLocation)
        : this.getPageLocation(`${this.document.location.origin}${params.pagePath}`),
    });
  }

  public handleRouteEvents(): void {
    const excludedUrls: RegExp[] = [/(\/driver$)|(\/driver\/$)/];
    let prevUrl: string;

    this.router.events
      .pipe(
        filter((event) => {
          if (event instanceof NavigationEnd && prevUrl !== event.urlAfterRedirects) {
            const urlIsExcluded: boolean = excludedUrls.some((regex) => regex.test(event.urlAfterRedirects));

            return !urlIsExcluded;
          }

          return false;
        }),
        debounceTime(2000),
      )
      .subscribe((event: NavigationEnd) => {
        prevUrl = event.urlAfterRedirects;

        this.sendPageView({ pagePath: event.urlAfterRedirects });
      });
  }

  private getPageLocation(location: string = this.document.location.href): string {
    try {
      const filteredQP: Record<string, string[]> = {
        login: ['session_state', 'code', 'state'],
      };
      const pagesPathToFilter = Object.keys(filteredQP);

      return pagesPathToFilter.reduce((acc, path): string => {
        if (location.includes(path)) {
          const newUrl = new URL(location);

          filteredQP[path].forEach((paramName) => {
            newUrl.searchParams.set(paramName, '[filtered]');
          });

          return newUrl.toString();
        }

        return acc;
      }, location);
    } catch (_e) {
      return '';
    }
  }
}
