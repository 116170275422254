import { Action } from '@ngrx/store';

export enum GlobalActionTypes {
  CHANGE_APP_VIEW = 'CHANGE_APP_VIEW',
}

export class ChangeAppView implements Action {
  public readonly type: GlobalActionTypes = GlobalActionTypes.CHANGE_APP_VIEW;
  constructor(public payload: any) {}
}

export type GlobalActionsUnion = ChangeAppView;
