import { Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppView } from '@shared/consts';

import * as globalActions from './store/actions/global.actions';
import * as fromRoot from './store/reducers';

export const MOBILE_VIEW_MAX_WIDTH: number = 768;
export const TABLET_VIEW_MAX_WIDTH: number = 1024;
export const ON_APP_INIT: InjectionToken<Observable<void>> = new InjectionToken('ON_APP_INIT');

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private appWidth: number;
  private appView: AppView;

  constructor(private store: Store<fromRoot.State>) {
    this.appWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.store.dispatch(new globalActions.ChangeAppView(this.getAppViewByWidth(this.appWidth)));
    this.appView = this.getAppViewByWidth(this.appWidth);
    window.addEventListener('resize', (event: any): void => {
      if (this.appWidth !== window.innerWidth) {
        this.appWidth = window.innerWidth;
        const newAppView: AppView = this.getAppViewByWidth(window.innerWidth);
        if (this.appView !== newAppView) {
          this.appView = newAppView;
          this.store.dispatch(new globalActions.ChangeAppView(newAppView));
        }
      }
    });
  }

  private getAppViewByWidth(width: number): AppView {
    if (width <= MOBILE_VIEW_MAX_WIDTH) {
      return AppView.Mobile;
    } else if (width <= TABLET_VIEW_MAX_WIDTH) {
      return AppView.Tablet;
    }
    return AppView.Desktop;
  }
}
