import { Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { minBy } from 'lodash';

import {
  Driver,
  DriverState,
  FleetType,
  ReferenceType,
  TypedTypePolicies,
  VehicleDeviceModelType,
  VehicleKindType,
  VehicleType,
  VehicleWorkingTimeSource,
} from '../generated';

import { permittedGroupIds } from './vehicle-group.type-policy';

export const Vehicle: TypedTypePolicies['Vehicle'] = {
  fields: {
    label: {
      read(_, { readField }): string {
        const registrationNumberAsName = readField('registrationNumberAsName', readField('appearance'));

        if (registrationNumberAsName) {
          return readField('registrationNumber');
        }

        return readField('name');
      },
    },
    identifiedDrivers: {
      read(_, { readField, toReference }): Reference[] {
        const drivers = readField('drivers') ?? [];
        if (Array.isArray(drivers)) {
          return drivers.reduce((acc, ref) => {
            if (readField('driverState', ref) === DriverState.IDENTIFIED) {
              acc.push(toReference(ref));
            }

            return acc;
          }, []);
        }

        return [];
      },
    },
    authorizedDrivers: {
      read(_, { readField, toReference }): Reference[] {
        const drivers = readField('drivers') ?? [];
        if (Array.isArray(drivers)) {
          return drivers.reduce((acc, ref) => {
            if (readField('isAuthorized', ref)) {
              acc.push(toReference(ref));
            }

            return acc;
          }, []);
        }

        return [];
      },
    },
    hasAuthorizedDrivers: {
      read(_, { readField }): boolean {
        const authorizedDrivers = readField('authorizedDrivers') || [];

        if (Array.isArray(authorizedDrivers)) {
          return authorizedDrivers.length > 0;
        }

        return false;
      },
    },
    currentDriver: {
      read(_, { readField }): Driver {
        const drivers = readField('drivers') || [];

        if (Array.isArray(drivers) && drivers.length > 0) {
          return drivers[0];
        }

        return null;
      },
    },
    assistDevice: {
      read(_, { readField }): Reference {
        const devices = readField('devices');

        if (Array.isArray(devices)) {
          return devices.find((device) => readField('model', device) === VehicleDeviceModelType.GBOX_ASSIST) ?? null;
        }

        return null;
      },
    },
    hasOBU: {
      read(_, { readField }): boolean | null {
        const devices = readField('devices');

        if (Array.isArray(devices)) {
          return devices.some((device) => readField('model', device) === VehicleDeviceModelType.OBU);
        }

        return null;
      },
    },
    hasAssist: {
      read(_, { readField }): boolean | null {
        const devices = readField('devices');

        if (Array.isArray(devices)) {
          return devices.some((device) => readField('model', device) === VehicleDeviceModelType.GBOX_ASSIST);
        }

        return null;
      },
    },
    profile: {
      read(_, { toReference, readField }): Reference | null {
        const typeId = readField('type');

        if (typeId != null) {
          const reference: Reference = toReference(<ReferenceType<VehicleType>>{
            __typename: 'VehicleType',
            id: typeId,
          });

          if (readField('id', reference) != null) {
            return reference;
          }
        }

        return null;
      },
    },
    fleetTypes: {
      read(_, { readField }): FleetType[] {
        const fleetTypes = [];

        if (readField('hasOBU')) {
          fleetTypes.push(FleetType.OBU);
        }

        if (readField('hasAssist')) {
          fleetTypes.push(FleetType.GBOX);
        }

        if (fleetTypes.length === 0) {
          fleetTypes.push(FleetType.GBOX);
        }

        return fleetTypes;
      },
    },
    defaultGroup: {
      read(_, { readField }): Reference | null {
        const groupsRefs: readonly Reference[] = readField('groups') || [];
        const minGroupRef: Reference = minBy(groupsRefs, (ref) => {
          const groupId: number = readField('id', ref);

          return permittedGroupIds().includes(groupId) ? groupId : undefined;
        });

        return minGroupRef || null;
      },
    },
    hasWorkingTimeSource: {
      read(_, { readField }): boolean | null {
        const workingTimeSource: string = readField('workingTimeSource');

        return workingTimeSource && workingTimeSource !== VehicleWorkingTimeSource.NONE;
      },
    },
    isTrailer: {
      read(_, { readField }): boolean | null {
        const kind = readField('kind');

        return kind ? kind === VehicleKindType.TRAILER : null;
      },
    },
    etaLightTasks: {
      read(state): any[] {
        return state || [];
      },
      merge(_existing = [], incoming: any[]) {
        return [...incoming];
      },
    },
    etaOrderTasks: {
      read(state): any[] {
        return state || [];
      },
      merge(_existing = [], incoming: any[]) {
        return [...incoming];
      },
    },
    hasEtaLightMonitors: {
      read(_, { readField }): null | boolean {
        return !!(readField<Reference[]>('currentEtaMonitors') || []).find((monitorRef) =>
          readField('isEtaLightSource', monitorRef),
        );
      },
    },
    currentEtaMonitors: {
      read(_, { readField }): Reference[] {
        const etaOrderTask: Reference | undefined = getImportantEta(
          readField<Reference[]>('etaOrderTasks') || [],
          readField,
          'arriveTimeEnd',
        );

        const etaLightTask: Reference | undefined = getImportantEta(
          readField<Reference[]>('etaLightTasks') || [],
          readField,
          'plannedArrivalTime',
        );

        return [etaLightTask, etaOrderTask].filter((et) => !!et);
      },
      merge(_existing = [], incoming: any[]) {
        return [...incoming];
      },
    },
    isBig: {
      read(_, { readField }): boolean | null {
        const type: number | null = readField('type');

        return type ? type >= 2 : null;
      },
    },
  },
};

function getImportantEta(
  refs: Reference[] | Readonly<Reference[]>,
  readField: ReadFieldFunction,
  arriveTimeKeyField: 'arriveTimeEnd' | 'plannedArrivalTime',
): Reference | undefined {
  return refs.reduce((acc: Reference | undefined, ref: Reference) => {
    if (!acc) {
      return ref;
    }

    const maxArriveTimeEnd = readField(arriveTimeKeyField, acc);
    const arriveTimeEnd = readField(arriveTimeKeyField, ref);

    if (maxArriveTimeEnd === arriveTimeEnd) {
      if (readField('id', ref) < readField('id', acc)) {
        return ref;
      }
    }

    if (arriveTimeEnd && arriveTimeEnd < maxArriveTimeEnd) {
      return ref;
    }

    return acc;
  }, undefined);
}
