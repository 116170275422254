import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, inject } from '@angular/core';
import { makeReference, Reference } from '@apollo/client/core';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { Subject, Observable } from 'rxjs';
import { filter, pairwise, takeUntil, tap } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@app/@config';
import { ON_APP_INIT } from '@app/app.service';
import { AuthService } from '@core/auth';

import { BlobResponse } from '../services/blob-response';

@Injectable({
  providedIn: 'root',
})
export class GraphQLService {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private readonly appConfig: AppConfig = inject(APP_CONFIG);

  constructor(
    private injector: Injector,
    private authService: AuthService,
    private translateService: TranslateService,
    private http: HttpClient,
    @Inject(ON_APP_INIT) private onAppInit$: Subject<void>,
  ) {}

  public initialize(): void {
    this.onAppInit$.subscribe(() => {
      this.subscribeToLogOut();
      this.subscribeToChangeLanguage();
    });
  }

  public identify<T extends { __typename?: string; id?: T['id'] | Partial<T> }>(
    typename: T['__typename'],
    id: T['id'],
  ): string {
    let identifyObj = { __typename: typename };

    if (typeof id === 'object') {
      identifyObj = { ...identifyObj, ...id };
    } else {
      identifyObj['id'] = id;
    }

    return this.apollo.client.cache.identify(identifyObj);
  }

  public getReference<T extends { __typename?: string; id?: T['id'] }>(
    typename: T['__typename'],
    id: T['id'],
  ): Reference {
    return makeReference(this.identify(typename, id));
  }

  public downloadFiles(
    fileIds: string[],
    fileName?: string,
    options?: { skipMissingFiles?: boolean; avoidCompressing?: boolean },
  ): Observable<HttpResponse<ArrayBuffer>> {
    const url: string = `${this.appConfig.facadeServiceUrl}/files`;

    return this.http
      .post(url, { ids: fileIds }, { responseType: 'arraybuffer', observe: 'response', params: { ...(options || {}) } })
      .pipe(
        tap((response) => {
          BlobResponse.showFileSavingWindow(response, fileName);
        }),
      );
  }

  private get apollo(): Apollo {
    return this.injector.get(Apollo);
  }

  private subscribeToLogOut(): void {
    this.authService.isAuthenticated$
      .pipe(
        pairwise(),
        filter(
          ([prevIsAuthenticated, currentIsAuthenticated]: [boolean, boolean]) =>
            prevIsAuthenticated && !currentIsAuthenticated,
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(async () => {
        await this.clearStore();
      });
  }

  private subscribeToChangeLanguage(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe(async () => {
      await this.clearStore();
      await this.apollo.client.reFetchObservableQueries();
    });
  }

  private clearStore(): Promise<void[]> {
    this.apollo.client.stop();

    return this.apollo.client.clearStore();
  }
}
