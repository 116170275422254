import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as HttpStatus from 'http-status-codes';
import { defer, from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '@core/auth';

import { HttpInterceptorHelper } from '../http-interceptor-helper';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private authService: AuthService;
  private refreshToken$: Observable<void>;

  constructor(private interceptorHelper: HttpInterceptorHelper, private injector: Injector) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.interceptorHelper.isRefreshRequest(request) || !this.interceptorHelper.isJwtAuthHost(request)) {
      return next.handle(request).pipe(
        catchError(async (error: HttpErrorResponse) => {
          if (error.status === HttpStatus.UNAUTHORIZED && this.interceptorHelper.isRefreshRequest(request)) {
            await this.authService.logout();
          }

          throw error;
        }),
      );
    }

    // Circural dependency...
    if (!this.authService || !this.refreshToken$) {
      this.authService = this.injector.get(AuthService);
      this.refreshToken$ = from(defer(() => this.authService.session.refresh()));
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatus.UNAUTHORIZED) {
            return this.refreshToken$.pipe(
              catchError(async (e: Error) => {
                if (this.interceptorHelper.isRefreshRequest(request)) {
                  await this.authService.logout();
                }

                return throwError(() => e);
              }),
              switchMap(() =>
                next.handle(this.interceptorHelper.addToken(request, this.authService.session?.accessToken)),
              ),
            );
          }
        } else {
          return throwError(error);
        }

        return throwError(() => error);
      }),
    );
  }
}
