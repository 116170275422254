import { HttpClient, HttpClientModule, HttpHeaders, HttpResponse } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ApolloLink, Operation, NextLink, from } from '@apollo/client/core';
import { RetryLink } from '@apollo/client/link/retry';
import { TranslateService } from '@ngx-translate/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkHandler } from 'apollo-angular/http';
import QueueLink from 'apollo-link-queue';
import { RestLink } from 'apollo-link-rest';
import { firstValueFrom, Observable } from 'rxjs';

import { AppConfig, APP_CONFIG } from '@app/@config';
import { ApolloCreate } from '@shared/graphql/graphql.model';
import { environment } from 'src/environments/environment';

import { camelCase } from '../utils';

import { GraphQLService } from './graphql.service';
import { memoryCache } from './memory.cache';
import { languageCodeToAcceptLanguageHeaderCode, restDirectiveResponseTransformer } from './utils';

export function createApollo(
  httpLink: HttpLink,
  appConfig: AppConfig,
  translateService: TranslateService,
  httpClient: HttpClient,
): ApolloCreate {
  const uri: string = `${appConfig.facadeServiceUrl}/graphql`;

  const http: HttpLinkHandler = httpLink.create({ uri });
  const retryLink = new RetryLink({
    attempts: {
      max: 5,
    },
    delay: {
      initial: 1 * 60 * 1000,
      max: 2 * 60 * 1000,
    },
  });

  const queueLink = new QueueLink();

  const RESTLink = new RestLink({
    uri: appConfig.packetServiceUrl,
    fieldNameNormalizer: (key: string) => camelCase(key),
    customFetch: (url, request): Promise<Response> => {
      const httpClientRequest: Observable<HttpResponse<string>> = httpClient.request(request.method, url.toString(), {
        observe: 'response',
        responseType: 'text',
        reportProgress: false,
        body: request.body,
        headers: new HttpHeaders(request.headers as Record<string, string>),
      });

      return firstValueFrom(httpClientRequest)
        .then(({ body, status, statusText }) => {
          return new Response(body, { status, statusText });
        })
        .catch(({ error, status, statusText }) => {
          return new Response(error, { status, statusText });
        });
    },
    endpoints: {
      auth: {
        uri: appConfig.authServiceUrl,
        responseTransformer: restDirectiveResponseTransformer,
      },
      core: {
        uri: appConfig.coreServiceUrl,
        responseTransformer: restDirectiveResponseTransformer,
      },
    },
  });

  const headersLink: ApolloLink = new ApolloLink((operation: Operation, forward: NextLink) => {
    operation.setContext({
      headers: {
        'Accept-Language': languageCodeToAcceptLanguageHeaderCode(translateService.currentLang),
      },
    });

    return forward(operation);
  });

  window.addEventListener('offline', () => queueLink.close());
  window.addEventListener('online', () => queueLink.open());

  return {
    link: from([headersLink, RESTLink, retryLink, http]),
    cache: memoryCache(),
    connectToDevTools: !environment.production,
  };
}

@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, APP_CONFIG, TranslateService, HttpClient],
    },
    {
      provide: APP_INITIALIZER,
      deps: [GraphQLService],
      useFactory: (graphqlService: GraphQLService) => () => {
        graphqlService.initialize();
      },
      multi: true,
    },
  ],
})
export class GraphQLModule {}
