import { isAfter, subHours } from 'date-fns';

import {
  Eta,
  EtaArrival,
  EtaLightTask,
  EtaMonitorTask,
  EtaTimeStatus,
  OrderTask,
  TaskCancelReason,
  TypedTypePolicies,
} from '../generated';

export const EtaMonitor: TypedTypePolicies['EtaMonitor'] = {
  fields: {
    etaTimeStatus: {
      read(_, { readField }): EtaTimeStatus | null {
        const eta: Eta | undefined = readField('eta');

        if (!eta) {
          return EtaTimeStatus.UNKNOWN;
        }

        const typename: EtaMonitorTask['__typename'] = readField('__typename');
        const plannedArrivalTimeField:
          | keyof Pick<OrderTask, 'arriveTimeEnd'>
          | keyof Pick<EtaLightTask, 'plannedArrivalTime'> =
          typename === 'EtaLightTask' ? 'plannedArrivalTime' : 'arriveTimeEnd';

        const arrivalTime: Date = readField(plannedArrivalTimeField);
        const etaArrivalTime: Date = readField('withWorkTime', readField('arrival', eta));

        if (!arrivalTime || !etaArrivalTime) {
          return EtaTimeStatus.UNKNOWN;
        } else if (isAfter(etaArrivalTime, arrivalTime)) {
          return EtaTimeStatus.EXCEEDED;
        } else if (isAfter(etaArrivalTime, subHours(arrivalTime, 1))) {
          return EtaTimeStatus.ENDANGERED;
        }

        return EtaTimeStatus.OK;
      },
    },
    isCalculated: {
      read(_, { readField }): boolean | null {
        const eta: Eta | undefined = readField(<keyof Pick<EtaMonitorTask, 'eta'>>'eta');
        if (!eta) {
          return null;
        }

        const val: boolean | undefined = readField(
          <keyof Pick<EtaArrival, 'withWorkTime'>>'withWorkTime',
          readField(<keyof Pick<Eta, 'arrival'>>'arrival', eta),
        );

        return val == null ? null : !!val;
      },
    },
    isExceeded: {
      read: isTimeStatus(EtaTimeStatus.EXCEEDED),
    },
    isEndangered: {
      read: isTimeStatus(EtaTimeStatus.ENDANGERED),
    },
    isOk: {
      read: isTimeStatus(EtaTimeStatus.OK),
    },
    isUnknown: {
      read: isTimeStatus(EtaTimeStatus.UNKNOWN),
    },
    isCanceled: {
      read(_, { readField }) {
        const cancelReason: Readonly<TaskCancelReason> | undefined | null = readField(
          <keyof Pick<EtaLightTask, 'cancelReason'>>'cancelReason',
        );

        return !!cancelReason;
      },
    },
    isEtaLightSource: {
      read(_, { readField }): boolean | null {
        const typename: EtaMonitorTask['__typename'] | undefined = readField('__typename');

        return typename ? typename === 'EtaLightTask' : null;
      },
    },
    isOrderTaskSource: {
      read(_, { readField }): boolean | null {
        const typename: EtaMonitorTask['__typename'] | undefined = readField('__typename');

        return typename ? typename === 'OrderTask' : null;
      },
    },
    sourceId: {
      read(_, { readField }): string | null {
        const typename: EtaMonitorTask['__typename'] | undefined = readField('__typename');
        const id: EtaMonitorTask['id'] | undefined = readField('id');

        return typename ? `${typename}:${id}` : null;
      },
    },
  },
};

function isTimeStatus(status: EtaTimeStatus): (...args: any) => boolean | null {
  return (_, { readField }) => {
    const timeStatus: EtaTimeStatus | null = readField('etaTimeStatus' as keyof Pick<EtaLightTask, 'etaTimeStatus'>);

    return timeStatus ? timeStatus === status : null;
  };
}
