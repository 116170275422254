import { Injectable } from '@angular/core';

import { PostMessageService } from './post-message.service';

@Injectable()
export class MessageHandlerService {
  constructor(private postMessageService: PostMessageService) {}

  public initialize(): void {
    window.addEventListener('message', (event: MessageEvent) => this.postMessageService.receiveMessage(event), false);
  }
}
