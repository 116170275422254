export function isObject(item): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep<T extends Record<string, any>, S extends Record<string, any>>(target: T, source: S): T & S {
  const output: (T & S) | {} = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }

  return output as T & S;
}
