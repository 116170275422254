<p>
  {{ message | translate }}
  <ng-container *ngIf="reloadMode === ReloadMode.HARD">
    <span>{{ displayCountdown }}</span>
  </ng-container>
</p>
<button mdbBtn (click)="updateApp()" class="reload">
  {{ '__INFO.UPDATE' | translate }}
</button>
<button mdbBtn (click)="closeDialog()" *ngIf="reloadMode === ReloadMode.HARD" class="close">
  <i class="material-icons">close</i>
</button>
