import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AuthTokenInterceptor } from './auth-token/auth-token.interceptor';
import { HttpInterceptorHelper } from './http-interceptor-helper';
import { InjectMonitoringHeadersInterceptor } from './monitoring-headers/inject-monitoring-headers.interceptor';
import { PostNgswBypassInterceptor } from './post-ngsw-bypass/post-ngsw-bypass.interceptor';
import { RefreshTokenInterceptor } from './refresh-token/refresh-token.interceptor';

@NgModule({
  providers: [
    HttpInterceptorHelper,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PostNgswBypassInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InjectMonitoringHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
  ],
})
export class InterceptorsModule {}
