import { PROTECTED_CONFIG } from '../models';

import { ProtectedConfigResolver } from './protected-config.resolver';

export const ProtectedConfigRouteConfig = {
  resolve: { protectedConfig: ProtectedConfigResolver },
  providers: [
    {
      provide: PROTECTED_CONFIG,
      useFactory: (resolver: ProtectedConfigResolver) => resolver.config,
      deps: [ProtectedConfigResolver],
    },
  ],
};
