import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig, APP_CONFIG } from '@app/@config';
import { JWTSession, CurrentUser } from '@core/auth';

import { userSerializer } from '../serializers';
import { RefreshTokenResponse, UserResponse } from '../types/api.type';

@Injectable()
export class AuthApiService {
  private readonly appConfig: AppConfig = inject(APP_CONFIG);

  constructor(private httpClient: HttpClient) {}

  public logoutUser(): Observable<boolean> {
    const serviceUrl: string = this.appConfig.iframeHref;

    return this.httpClient
      .get(`${serviceUrl}/admin/logout/`, {
        withCredentials: true,
        responseType: 'text',
      })
      .pipe(map(() => true));
  }

  public refreshSession(session: JWTSession): Observable<RefreshTokenResponse> {
    const serviceUrl: string = this.appConfig.refreshTokenUrl;

    return this.httpClient.get<RefreshTokenResponse>(serviceUrl, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${session.refreshToken}` },
    });
  }

  public getCurrentUser(): Observable<CurrentUser> {
    const serviceUrl: string = this.appConfig.authServiceUrl;

    return this.httpClient
      .get<UserResponse>(`${serviceUrl}/users/me?include=accessTypes`)
      .pipe(map((response: UserResponse) => userSerializer(response)));
  }
}
