import { Observable, Subject } from 'rxjs';

import { generateUUID } from '@shared/functions';

import { AuthApiService } from '../api/services/api.service';
import { RefreshTokenResponse } from '../api/types/api.type';
import { JWTAccessTokenPayload } from '../types';
import { payloadTimeToDate, getJwtPayload } from '../utils/jwt.util';

export const refreshSessionBefore: number = 3 * 60 * 1000;
export const prefetchingInterval: number = 30 * 1000;

// *** WAŻNE! ***
// Przed wprowadzeniem zmian odwiedź i zapoznaj się z sekcją "Sesja JWT" w wiki projektu
export class JWTSession {
  private _uuid: string;
  private _onRefresh$: Subject<JWTSession> = new Subject();
  private _onDestroy$: Subject<void> = new Subject();
  private _userId: number;
  private _clientId: number;
  private _serviceMode: boolean;
  private accessTokenExpDate: Date;
  private refreshTokenExpDate: Date;
  private refreshTokenIssuedAt: Date;
  private refreshTokenDuration: number;
  private refreshTimer: any;
  private refreshPromise: Promise<void> | null;

  constructor(
    private _accessToken: string,
    private _refreshToken: string,
    private authApiService: AuthApiService,
    uuid?: string,
  ) {
    this._uuid = uuid || generateUUID();

    this.setTokens(this.accessToken, this.refreshToken);
  }

  public get uuid(): string {
    return this._uuid;
  }

  public get accessToken(): string {
    return this._accessToken;
  }

  public get refreshToken(): string {
    return this._refreshToken;
  }

  public get userId(): number {
    return this._userId;
  }

  public get clientId(): number {
    return this._clientId;
  }

  public get serviceMode(): boolean {
    return this._serviceMode;
  }

  public get onRefresh$(): Observable<JWTSession> {
    return this._onRefresh$.asObservable();
  }

  public get onDestroy$(): Observable<void> {
    return this._onDestroy$.asObservable();
  }

  public accessTokenTimeLeft(): number {
    return this.accessTokenExpDate.getTime() - Date.now();
  }

  public refreshTokenTimeLeft(): number {
    return this.refreshTokenExpDate.getTime() - Date.now();
  }

  public async setup(): Promise<void> {
    if (this.shouldRefresh()) {
      await this.refresh();
    }

    this.startPrefetching();
  }

  public destroy(): void {
    this.clearPrefetching();

    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._onRefresh$.complete();
  }

  public refresh(): Promise<void> {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    this.refreshPromise = this.authApiService
      .refreshSession(this)
      .toPromise()
      .then(({ data }: RefreshTokenResponse) => {
        this.setTokens(data.accessToken, data.refreshToken);
        this._onRefresh$.next(this);
      })
      .finally(() => {
        this.refreshPromise = null;
      });

    return this.refreshPromise;
  }

  private setTokens(accessToken: string, refreshToken: string): void {
    const accessTokenPayload: JWTAccessTokenPayload = getJwtPayload(accessToken);
    const refreshTokenPayload: JWTAccessTokenPayload = getJwtPayload(refreshToken);

    if (!this.validatePayloads(accessTokenPayload, refreshTokenPayload)) {
      throw new Error('Invalid token payload');
    }

    this._accessToken = accessToken;
    this._refreshToken = refreshToken;
    this.accessTokenExpDate = payloadTimeToDate(accessTokenPayload.exp);
    this.refreshTokenExpDate = payloadTimeToDate(refreshTokenPayload.exp);
    this.refreshTokenIssuedAt = payloadTimeToDate(refreshTokenPayload.iat);
    this.refreshTokenDuration = this.refreshTokenExpDate.getTime() - this.refreshTokenIssuedAt.getTime();

    this._userId = +accessTokenPayload.sub;
    this._clientId = +accessTokenPayload.ctx.cid;
    this._serviceMode = !!accessTokenPayload.ctx.sm;
  }

  private shouldRefresh(): boolean {
    const refreshTokenTimeLeft: number = this.refreshTokenTimeLeft();

    return (
      this.accessTokenTimeLeft() <= refreshSessionBefore &&
      refreshTokenTimeLeft <= this.refreshTokenDuration &&
      refreshTokenTimeLeft >= 0
    );
  }

  private startPrefetching(): void {
    this.clearPrefetching();

    this.refreshTimer = setInterval(async () => {
      if (this.shouldRefresh()) {
        try {
          await this.refresh();
        } catch {}
      }
    }, prefetchingInterval);
  }

  private clearPrefetching(): void {
    clearInterval(this.refreshTimer);
  }

  private validatePayloads(accessTokenPayload: any, refreshTokenPayload: any): boolean {
    return (
      !!accessTokenPayload?.exp &&
      !!accessTokenPayload?.sub &&
      !!accessTokenPayload?.ctx?.cid &&
      !!refreshTokenPayload?.exp &&
      !!refreshTokenPayload?.iat
    );
  }
}
