import { inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { AppConfig, APP_CONFIG } from '@app/@config';
import { AuthService } from '@core/auth';
import { environment } from 'src/environments/environment';

@Injectable()
export class SentryService {
  private readonly appConfig: AppConfig = inject(APP_CONFIG);

  constructor(private authService: AuthService) {}

  public initialize(): void {
    const sentryDsn: string = this.appConfig.sentryDsn;
    const sentryWhitelistUrls: string[] = this.appConfig.sentryWhitelistUrls;

    if (!sentryDsn) {
      return;
    }

    Sentry.init({
      dsn: sentryDsn,
      allowUrls: sentryWhitelistUrls,
      release: environment.tag,
      integrations: [new BrowserTracing()],
    });

    this.authService.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
      if (!isAuthenticated) {
        Sentry.setUser(null);
        return;
      }

      Sentry.setUser({
        login: this.authService.currentUser.username,
        company: this.authService.client.name,
        clientId: this.authService.client.id,
      });
    });
  }

  public captureException(exception: any, extras: Record<string, any> = {}): void {
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setExtras(extras);

      Sentry.captureException(exception);
    });
  }
}
