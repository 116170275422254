import { NgModule, Optional, SkipSelf } from '@angular/core';

import { DateModule } from '@app/@shared/date';
import { BeamerModule } from '@core/beamer';
import { HotjarModule } from '@core/hotjar';
import { GraphQLModule } from '@shared/graphql';
import { InterceptorsModule } from '@shared/interceptors';

import { AuthModule } from './auth';
import { GoogleTagManagerModule } from './google-tag-manager';
import { IframeCommunicationModule } from './iframe-communication';
import { LanguageModule } from './language';
import { LibsModule } from './libs';
import { SentryModule } from './sentry';
import { UpdateModule } from './update';

@NgModule({
  imports: [
    AuthModule,
    InterceptorsModule,
    GoogleTagManagerModule,
    IframeCommunicationModule,
    LanguageModule,
    BeamerModule,
    HotjarModule,
    SentryModule,
    UpdateModule,
    LibsModule,
    DateModule,

    // Moduł GraphQL nie może być aktualnie ładowany lazy-lading, ponieważ biblioteka apollo-angular
    // ma buga https://github.com/kamilkisiela/apollo-angular/pull/1586
    // Po naprawie buga moduł należy importować w wymaganych modułach, a nie w globalnym module
    GraphQLModule,
  ],
  exports: [UpdateModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule should be loaded only once!');
    }
  }

  public static forRoot(): any {
    return {
      ngModule: CoreModule,
    };
  }
}
