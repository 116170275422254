import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { LocalStorageService } from '@shared/local-storage';

import { POSTLOCATIONS } from './post-locations';

@Injectable()
export class PostMessageRelocateService {
  private readonly _relocateFailed: Subject<any> = new Subject<any>();

  constructor(private router: Router, private readonly localStorageService: LocalStorageService) {}

  /**
   * We used this in case when router navigate to same route and change are not detected,
   * so we can subscribe to this situation and reload iframe manually
   */
  public onRelocateFailed(): Observable<any> {
    return this._relocateFailed.asObservable();
  }

  public handleLocation(location: string, newWindow: boolean = false): void {
    let matches: string[];
    let url: string;
    const radix10: number = 10;

    for (const item of POSTLOCATIONS) {
      url = item.target;

      if ((matches = location.match(item.location))) {
        for (const k in matches) {
          if (!isNaN(parseInt(k, radix10))) {
            url = url.replace('{' + k + '}', matches[k]);
          }
        }

        if (newWindow === true) {
          if (item.params) {
            const params: string = Object.keys(item.params)
              .map((key: string) => key + '=' + item.params[key])
              .join('&');

            window.open(url + '?' + params, '_blank');
            return;
          }

          window.open(url, '_blank');
        } else {
          const navigationExtras: NavigationExtras = {
            queryParams: item.params || {},
          };

          this.router
            .navigate([url], navigationExtras)
            .then((result: boolean) => {
              if (result === null) {
                this._relocateFailed.next(item);
              }
            })
            .catch(() => null);
        }

        return;
      }
    }
    console.error('POST LOCATION NOT FOUND!', location);
  }
}
