export class Position {
  public address: string = null;
  public lat: number = null;
  public long: number = null;

  public get lng(): number {
    return this.long;
  }

  constructor(lat: number, long: number, address: string = null) {
    this.lat = lat;
    this.long = long;
    this.address = address || null;
  }
}
