import { Option } from '@shared/model/common';

export const PhonePrefixes: Option<string>[] = [
  { label: 'AL', value: '+355' },
  { label: 'AD', value: '+376' },
  { label: 'AT', value: '+43' },
  { label: 'BY', value: '+375' },
  { label: 'BE', value: '+32' },
  { label: 'BA', value: '+387' },
  { label: 'BG', value: '+359' },
  { label: 'HR', value: '+385' },
  { label: 'CY', value: '+357' },
  { label: 'CZ', value: '+420' },
  { label: 'DK', value: '+45' },
  { label: 'EE', value: '+372' },
  { label: 'FO', value: '+298' },
  { label: 'FI', value: '+358' },
  { label: 'FR', value: '+33' },
  { label: 'DE', value: '+49' },
  { label: 'GI', value: '+350' },
  { label: 'GR', value: '+30' },
  { label: 'HU', value: '+36' },
  { label: 'IS', value: '+354' },
  { label: 'IE', value: '+353' },
  { label: 'IT', value: '+39' },
  { label: 'XK', value: '+381' },
  { label: 'LV', value: '+371' },
  { label: 'LI', value: '+423' },
  { label: 'LT', value: '+370' },
  { label: 'LU', value: '+352' },
  { label: 'MK', value: '+389' },
  { label: 'MT', value: '+356' },
  { label: 'MD', value: '+373' },
  { label: 'MC', value: '+377' },
  { label: 'ME', value: '+382' },
  { label: 'NL', value: '+31' },
  { label: 'NO', value: '+47' },
  { label: 'PL', value: '+48' },
  { label: 'PT', value: '+351' },
  { label: 'RO', value: '+40' },
  { label: 'RU', value: '+7' },
  { label: 'SM', value: '+378' },
  { label: 'RS', value: '+381' },
  { label: 'SK', value: '+421' },
  { label: 'SI', value: '+386' },
  { label: 'ES', value: '+34' },
  { label: 'SE', value: '+46' },
  { label: 'CH', value: '+41' },
  { label: 'UA', value: '+380' },
  { label: 'GB', value: '+44' },
  { label: 'VA', value: '+39' },
];
