export enum LocalStorageKeys {
  Session = 'session',

  Language = 'language',

  SelectedGroup = 'selectedGroup_',

  MapRouteOptions = 'routeMapOptions',
  MapLayer = 'map_layer',
  MapVector = 'map_vector',
  MapTraffic = 'map_traffic',
  MapRouteProhibitions = 'map_routeProhibitions',
  MapMarkersGrouping = 'markers_grouping',
  MapRoutingMarkersVisible = 'routing_markers_visible',
  SelectedDevicesType = 'selected_devices_types',

  WorkingTimeCardReadingInfoCollapsed = 'cardReadingInfoCollapsed',
  ETALightTermsAccepted = 'etaLightTermsAccepted',

  NewMapSatelliteViewEnabled = 'new_map_satellite_view_enabled',
  NewMapVectorStyleEnabled = 'new_map_vector_style_enabled',
  NewMapTrafficEnabled = 'new_map_traffic_enabled',
  NewMapRouteProhibitionsEnabled = 'new_map_route_prohibitions_enabled',
  NewMapVehiclesFilters = 'new_map_vehicles_filters',
  NewMapVehiclesGrouping = 'new_map_vehicles_grouping',
  NewMapRoutingFleetVisible = 'new_map_routing_fleet_visible',
  NewMapRAWModeEnabled = 'new_map_raw_mode_enabled',

  OnboardingStepIndex = 'onboarding_step_index',
  ShowDigitalReadsFinishedConfigurationInfo = 'show_digital_reads_finished_configuration_info',
  HideDigitalReadsUpgradeAd = 'hide_digital_reads_upgrade_ad',
  ShowDigitalReadsAutomaticReadsInfo = 'show_digital_reads_automatic_reads_info',

  HideCardReaderAd = 'hide_digital_reads_card_reader_ad',
  EcoDrivingReportId = 'ecoDrivingReportId',
}
