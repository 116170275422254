function hexToRgbArray(hexColor: string): number[] {
  const color: RegExpMatchArray = hexColor
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (_m: string, r: string, g: string, b: string) => '#' + r + r + g + g + b + b,
    )
    .substring(1)
    .match(/.{2}/g);

  return (color || []).map((x: string) => parseInt(x, 16));
}

export function hexToRgba(hexColor: string, opacity: number): string {
  const rgbArray: number[] = hexToRgbArray(hexColor);
  rgbArray.push(opacity);

  return `rgba(${rgbArray.join(', ')})`;
}

export function invertHexColor(hexColor: string): string {
  const rgbArray: number[] = hexToRgbArray(hexColor);

  return rgbArray[0] * 0.299 + rgbArray[1] * 0.587 + rgbArray[2] * 0.114 > 186 ? '000000' : 'ffffff';
}
