import { ChangeDetectorRef, Component, HostBinding, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppConfig, APP_CONFIG } from '@app/@config';

import { ReloadMode } from '../../reload-mode.enum';
import { UpdateService } from '../../update.service';

@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss'],
})
export class UpdateModalComponent implements OnInit, OnDestroy {
  @HostBinding('class.hidden') public hidden: boolean = false;

  public displayCountdown: string = '';
  public ReloadMode: typeof ReloadMode = ReloadMode;
  public reloadMode: ReloadMode = ReloadMode.HARD;
  public message: string = '__INFO.RELOAD_APP_SOFT_MESSAGE';

  private unsubscribe$: Subject<void> = new Subject<void>();
  private readonly appConfig: AppConfig = inject(APP_CONFIG);
  private countdown: number = this.appConfig.updateHardReloadCountdown;

  constructor(private updateService: UpdateService, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.reloadMode = this.updateService.getReloadMode();

    switch (this.reloadMode) {
      case ReloadMode.SOFT:
        this.message = '__INFO.RELOAD_APP_SOFT_MESSAGE';
        break;
      case ReloadMode.HARD:
        this.message = '__INFO.RELOAD_APP_HARD_MESSAGE';
        this.displayCountdown = `${Math.round(this.countdown / 60000)} min`;
        this._startCountingDown();
        break;
    }

    this.changeDetector.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public updateApp(): void {
    this.updateService.updateApp();
  }

  public closeDialog(): void {
    this.hidden = true;
  }

  private _startCountingDown(): void {
    timer(1000, 1000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.countdown -= 1000;
        if (this.countdown <= 0) {
          this.updateService.updateApp();
        }

        if (this.countdown > 60000) {
          this.displayCountdown = `${Math.round(this.countdown / 60000)} min`;
        } else {
          this.displayCountdown = `${Math.round(this.countdown / 1000)} s`;
        }

        this.changeDetector.detectChanges();
      });
  }
}
