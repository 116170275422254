import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { ProtectedConfigLoader } from '../loaders';
import { ProtectedConfig } from '../models';

@Injectable()
export class ProtectedConfigResolver implements Resolve<ProtectedConfig> {
  public config?: ProtectedConfig;

  public async resolve(): Promise<ProtectedConfig> {
    this.config = this.config ?? (await ProtectedConfigLoader.load());

    return this.config;
  }
}
