import { UserPoiFilePath } from '@app/@shared/model/user-point.model';

import { TypedTypePolicies, UserPointIconName } from '../generated';

export const UserPoint: TypedTypePolicies['UserPoint'] = {
  fields: {
    iconName: {
      read(_, { readField }): UserPointIconName {
        const filePath: string = readField('filepath');

        switch (filePath) {
          case UserPoiFilePath.Anchor:
            return UserPointIconName.ANCHOR;
          case UserPoiFilePath.Flag1:
            return UserPointIconName.FLAG1;
          case UserPoiFilePath.Flag2:
            return UserPointIconName.FLAG2;
          case UserPoiFilePath.GasStation:
            return UserPointIconName.GASSTATION;
          case UserPoiFilePath.House:
            return UserPointIconName.HOUSE;
          case UserPoiFilePath.Info:
            return UserPointIconName.INFO;
          case UserPoiFilePath.Order:
            return UserPointIconName.ORDER;
          case UserPoiFilePath.Parking:
            return UserPointIconName.PARKING;
          case UserPoiFilePath.Poi:
            return UserPointIconName.POI;
          case UserPoiFilePath.Sign:
            return UserPointIconName.SIGN;
          case UserPoiFilePath.Wrench:
            return UserPointIconName.WRENCH;
          case UserPoiFilePath.Bomb:
          case 'bomb.png':
            return UserPointIconName.BOMB;
          default:
            return null;
        }
      },
    },
  },
};
