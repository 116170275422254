import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { APP_CONFIG, AppConfig } from '@app/@config';
import { SSoSessionParams } from '@modules/login/pages';
import { WINDOW } from '@shared/injection-tokens/window.token';

@Injectable()
export class SsoService {
  private readonly _cookieLanguageKey: string = 'KEYCLOAK_LOCALE';
  private readonly _cookieLanguageExpDays: number = 365;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cookies: CookieService,
    @Inject(WINDOW) private window: Window,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
  ) {}

  /**
   * Use to obtain code and session_state URL parameters set by SSO Provider in the redirect_uri after
   * successful login.
   * code parameter can be used to exchange to OIDC token (access_token, refresh_token, id_token).
   */
  public getSessionParams(): SSoSessionParams | undefined {
    const params: Record<string, string> = this.activatedRoute.snapshot.queryParams;

    if (params.session_state && params.code) {
      return { session_state: params.session_state, code: params.code };
    }
  }

  /**
   * Use to redirect user to SSO login page using config parameters for application.
   */
  public redirectToLoginPage(): void {
    const url: URL = new URL(
      `${this.appConfig.ssoConfig.host}/realms/${this.appConfig.ssoConfig.realm}/protocol/openid-connect/auth`,
    );

    url.searchParams.set('state', this.getState());
    url.searchParams.set('scope', this.appConfig.ssoConfig.scope);
    url.searchParams.set('redirect_uri', `${this.window.location.protocol}//${this.window.location.host}/login`);
    url.searchParams.set('response_type', this.appConfig.ssoConfig.responseType);
    url.searchParams.set('client_id', this.appConfig.ssoConfig.clientId);

    this.window.location.href = url.toString();
  }

  /**
   * Use to obtain user's language from cookie set initially on the SSO login page
   */
  public getLanguage(): string {
    return this.cookies.get(this._cookieLanguageKey);
  }

  /**
   * Use to set current user's language in cookie used in SSO service
   */
  public setLanguage(localeID: string): void {
    // Strip subdomain and port
    const domain: string = '.' + this.window.location.host.replace(/^[^.]+\./g, '').replace(/:\d+$/g, '');

    // Expire after 1 year
    const cookieExp: Date = this.getCookieExpDate();

    this.cookies.set(this._cookieLanguageKey, localeID, cookieExp, '/', domain, true, 'None');
  }

  private getCookieExpDate(): Date {
    const cookieExp: Date = new Date();
    cookieExp.setDate(cookieExp.getDate() + this._cookieLanguageExpDays);

    return cookieExp;
  }

  private getState(): string {
    return this.randomString(32);
  }

  private randomString(len): string {
    const charSet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randString: string = '';
    let randPos: number = 0;
    for (let i: number = 0; i < len; i++) {
      randPos = Math.floor(Math.random() * charSet.length);
      randString += charSet.substring(randPos, randPos + 1);
    }
    return randString;
  }
}
