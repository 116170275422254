import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Subject } from 'rxjs';

import { APP_CONFIG, AppConfig } from '@app/@config';
import { CoreModule } from '@core/core.module';

import { environment } from '../environments/environment';

import { AppErrorHandler } from './app-error-handler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ON_APP_INIT } from './app.service';
import { AppEffects } from './store/effects/app.effects';
import { reducers, metaReducers } from './store/reducers';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      // if you want to change the enabled flag check PostNgswBypassInterceptor
      scope: '/',
      registrationStrategy: 'registerImmediately',
    }),
    EffectsModule.forRoot([AppEffects]),
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: ON_APP_INIT, useValue: new Subject() },
    {
      provide: SwRegistrationOptions,
      useFactory: (appConfig: AppConfig) => ({ enabled: environment.production && !appConfig.serviceWorkerDisabled }),
      deps: [APP_CONFIG],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
