import { Position } from './position.model';

export class UserPoint {
  public name: string;
  public position: Position;
  public filepath: string;
  public address: string;
  public iconName: string;

  constructor(point: any) {
    this.name = point.name || '';
    this.position = new Position(point.position.latitude, point.position.longitude);
    this.iconName = this.resolveIconName(point.filepath);
    this.address = point.address || '';
  }

  private resolveIconName(filepath: string): string {
    return filepath.split('.')[0];
  }
}

export enum UserPoiFilePath {
  Bomb = 'bomba.png',
  Anchor = 'anchor.png',
  Flag1 = 'flag_1.png',
  Flag2 = 'flag_2.png',
  GasStation = 'gasstation.png',
  House = 'house.png',
  Info = 'info.png',
  Order = 'order.png',
  Parking = 'parking.png',
  Poi = 'poi.png',
  Sign = 'sign.png',
  Wrench = 'wrench.png',
}
