import { AppView } from '@shared/consts';

import { GlobalActionsUnion, GlobalActionTypes } from '../actions/global.actions';

export interface State {
  appView: AppView;
}

const initialState: State = {
  appView: AppView.Desktop,
};

export function reducer(state: State = initialState, action: GlobalActionsUnion): State {
  switch (action.type) {
    case GlobalActionTypes.CHANGE_APP_VIEW:
      return {
        ...state,
        appView: action.payload,
      };
    default:
      return state;
  }
}

export const getAppView: (state: State) => AppView = (state: State) => state.appView;
