import { isToday, format } from '@shared/date';

export const hoursToSeconds = (hours: number): number => hours * 3600;

export const minutesToHoursMinutes = (minutes: number): string =>
  Math.floor(Math.abs(minutes) / 60) + ' h ' + (Math.abs(minutes) % 60).toString().padStart(2, '0') + ' m';

export const todayDateFormat = (
  date: Date,
  todayText: string,
  fullFormat = 'dd.MM.yyyy HH:mm',
  timeFormat = 'HH:mm',
): string => (isToday(date) ? `${todayText} ${format(date, timeFormat)}` : format(date, fullFormat));
