import { UserPermissions } from '@shared/model/user-permissions.model';

export class CurrentUser {
  constructor(
    public readonly id: number,
    public readonly isAdmin: boolean,
    public readonly username: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly phoneNumber: string,
    public readonly email: string,
    public readonly permissions: UserPermissions,
    public readonly isExpired: boolean,
  ) {}
}
