import { makeVar } from '@apollo/client/core';

import { invertHexColor } from '@app/@shared/utils';

import { TypedTypePolicies } from '../generated';

export const permittedGroupIds = makeVar([]);

export const VehicleGroupType: TypedTypePolicies['VehicleGroupType'] = {
  fields: {
    hexColor: {
      read(_, { readField }): string | null {
        return `#${(readField('color') as string)?.replace('#', '') || 'fff'}`;
      },
    },
    hexInvertedColor: {
      read(_, { readField }): string | null {
        return `#${invertHexColor(readField('hexColor'))}`;
      },
    },
  },
};
