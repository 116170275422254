import { DOCUMENT } from '@angular/common';
import { inject, Inject, Injectable } from '@angular/core';

import { AppConfig, APP_CONFIG } from '@app/@config';

@Injectable()
export class TagManagerScriptService {
  private readonly appConfig: AppConfig = inject(APP_CONFIG);
  private tagManagerId: string = this.appConfig.googleTagManagerId;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public init(): void {
    if (this.tagManagerId) {
      this.addTrackingScriptToHead();
      this.addTrackingScriptToBody();
    }
  }

  private addTrackingScriptToHead(): void {
    const script = this.document.createElement('script');
    script.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${this.tagManagerId}');
    `;
    const head = this.document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }

  private addTrackingScriptToBody(): void {
    const noscript = this.document.createElement('noscript');

    const iframe = this.document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.tagManagerId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    noscript.appendChild(iframe);

    const body = this.document.getElementsByTagName('body')[0];
    body.appendChild(noscript);
  }
}
