import { numberSerializer } from '@shared/graphql/type-policies/utils/serializer';

import { IncidentTypeKey, TypedTypePolicies } from '../generated';

export const Incident: TypedTypePolicies['Incident'] = {
  merge: true,
  fields: {
    geoLat: numberSerializer,
    geoLong: numberSerializer,
    objazd: {
      read(value): string | string[] {
        // Exclude invalid value: ['\n'] /['\n\n'] / []
        return Array.isArray(value) ? [].join('.') : value;
      },
    },
    position: {
      read(_, { readField }): [number, number] {
        return [readField('geoLong'), readField('geoLat')];
      },
    },
    type: {
      read(_, { readField }): string {
        return getIncidentType(readField('poz', readField('rodzaj')));
      },
    },
  },
};

export const getIncidentType = (incidentType: IncidentTypeKey): string => {
  switch (incidentType) {
    case IncidentTypeKey.I02:
      return 'Blokada drogi';
    case IncidentTypeKey.I04:
      return 'Inne';
    case IncidentTypeKey.I05:
      return 'Rajd';
    case IncidentTypeKey.I06:
      return 'Pielgrzymka';
    case IncidentTypeKey.I07:
      return 'Zawody sportowe';
    case IncidentTypeKey.I08:
      return 'Demonstracja';
    case IncidentTypeKey.I09:
      return 'Uroczystość';
    case IncidentTypeKey.I10:
      return 'Przejazd ważnej osobistości';
    case IncidentTypeKey.I11:
      return 'Przejazd pojazdu nienormatywnego';
    case IncidentTypeKey.I12:
      return 'Zwiększone natężenie ruchu';
    case IncidentTypeKey.I13:
      return 'Protest';
    case IncidentTypeKey.K00:
      return 'Katastrofa budowlana';
    case IncidentTypeKey.K01:
      return 'Katastrofa ekologiczna';
    case IncidentTypeKey.K02:
      return 'Pożar';
    case IncidentTypeKey.K03:
      return 'Inne';
    case IncidentTypeKey.K04:
      return 'Powódź';
    case IncidentTypeKey.S00:
      return 'Brak informacji';
    case IncidentTypeKey.U27:
      return 'Remont mostu';
    case IncidentTypeKey.U33:
      return 'Roboty drogowe';
    case IncidentTypeKey.U42:
      return 'Inne';
    case IncidentTypeKey.R00:
      return 'Zderzenie się pojazdów w ruchu - czołowe';
    case IncidentTypeKey.R01:
      return 'Zderzenie się pojazdów w ruchu - boczne';
    case IncidentTypeKey.R02:
      return 'Zderzenie się pojazdów w ruchu - tylne';
    case IncidentTypeKey.R03:
      return 'Najechanie pojazdu na pieszego';
    case IncidentTypeKey.R04:
      return 'Najechanie pojazdu na pojazd unieruchomiony';
    case IncidentTypeKey.R05:
      return 'Najechanie pojazdu na drzewo, słup';
    case IncidentTypeKey.R06:
      return 'Najechanie pojazdu na zaporę kolejową';
    case IncidentTypeKey.R07:
      return 'Najechanie pojazdu na urządzenie drogowe';
    case IncidentTypeKey.R08:
      return 'Najechanie pojazdu na zwierzę';
    case IncidentTypeKey.R09:
      return 'Wywrócenie się pojazdu';
    case IncidentTypeKey.R10:
      return 'Inne';
    case IncidentTypeKey.R11:
      return 'Zderzenie się pojazdów w ruchu';
    case IncidentTypeKey.R12:
      return 'Wypadnięcie pojazdu z jezdni';
    case IncidentTypeKey.R13:
      return 'Wjechanie pojazdu do rowu';
    case IncidentTypeKey.R14:
      return 'Awaria pojazdu';
    default:
      return 'Inne zdarzenie';
  }
};
