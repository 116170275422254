import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { environment } from '../environments/environment';

export class AppErrorHandler extends ErrorHandler {
  public handleError(err: any): void {
    super.handleError(err);

    if (environment.production && this.shouldLogSentryError(err)) {
      Sentry.captureException(err);
    }
  }

  private shouldLogSentryError(err: any): boolean {
    if (err.hasOwnProperty('message') && typeof err.message === 'string') {
      if (err.message.includes('ChunkLoadError')) {
        return false;
      }
    }

    return true;
  }
}
