import { TypedTypePolicies } from '../generated';

export const EtaArrival: TypedTypePolicies['EtaArrival'] = {
  fields: {
    withWorkTime: {
      read(value: string): Date {
        return value ? new Date(value) : null;
      },
    },
  },
};
