export function getJwtPayload(token: string): any {
  try {
    const base64: string = token.split('.')[1].replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  } catch {
    throw new Error('Invalid data');
  }
}

export function payloadTimeToDate(epoch: number): Date {
  return new Date(epoch * 1000);
}
