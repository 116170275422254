<div class="module-content">
  <ng-content></ng-content>
  <div class="not-found">
    <h2>{{ '__ERROR.PAGE_NOT_FOUND' | translate }}</h2>
    <p>
      {{ '__INFO.GO_TO' | translate }}
      <a routerLink="/map">{{ '__INFO.MAIN_PAGE' | translate }}</a>
    </p>
  </div>
</div>
